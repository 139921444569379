<template>
  <div class="page-status" :class="statusClass" @click="handleClick">
    <div class="status-icon" :class="{ 'pulsing': status === 'IN_PROGRESS' }">
      <v-icon small :color="statusColor">{{ statusIcon }}</v-icon>
    </div>
    <div class="status-content">
      <div class="status-name-row">
        <div class="status-name" :class="{ 'pulsing-text': status === 'IN_PROGRESS' }">{{ name }}</div>
        <div class="status-info">
          <div class="status-pill" :class="statusPillClass">
            {{ statusText }}
          </div>
          <div v-if="status === 'UNANSWERED'" class="completion-status">
            Not Completed
          </div>
        </div>
      </div>
    </div>
    <div v-if="clickable && status !== 'IN_PROGRESS'" class="status-action">
      <v-icon small color="primary">fas fa-chevron-right</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageStatus',
  
  props: {
    name: {
      type: String,
      required: true
    },
    status: {
      type: String,
      validator: value => ['PENDING', 'IN_PROGRESS', 'SYNCED', 'FAILED', 'UNANSWERED'].includes(value)
    },
    filled: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    statusClass() {
      return {
        'status-synced': this.status === 'SYNCED',
        'status-failed': this.status === 'FAILED',
        'status-pending': !this.filled,
        'status-progress': this.status === 'IN_PROGRESS',
        'clickable': this.clickable && this.status !== 'IN_PROGRESS'
      }
    },

    statusPillClass() {
      return {
        'synced': this.status === 'SYNCED',
        'failed': this.status === 'FAILED',
        'pending': this.status === 'UNANSWERED',
        'in-progress': this.status === 'IN_PROGRESS'
      }
    },

    statusColor() {
      if (this.status === 'FAILED') return 'error';
      if (this.status === 'SYNCED') return 'success';
      if (this.status === 'UNANSWERED') return 'grey';
      return 'warning';
    },

    statusIcon() {
      if (this.status === 'FAILED') return 'fas fa-times-circle';
      if (this.status === 'SYNCED') return 'fas fa-check-circle';
      if (this.status === 'UNANSWERED') return 'fas fa-exclamation-circle';
      if (this.status === 'IN_PROGRESS') return 'fas fa-sync';
      return 'fas fa-exclamation-circle';
    },

    statusText() {
      if (this.status === 'SYNCED') return 'Synced';
      if (this.status === 'FAILED') return 'Sync Failed';
      if (this.status === 'IN_PROGRESS') return 'Syncing...';
      if (this.status === 'UNANSWERED') return 'Unanswered';
      return 'Pending';
    }
  },

  methods: {
    handleClick() {
      if (this.clickable && this.status !== 'IN_PROGRESS') {
        this.$emit('click');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-status {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid var(--color-border, #E0E0E0);
  border-radius: 8px;
  background: var(--color-background, white);
  transition: all 0.2s ease;
  margin-bottom: 8px;

  @media screen and (max-width: 600px) {
    padding: 12px;
  }

  &.clickable {
    cursor: pointer;
    &:hover {
      background: var(--color-background-hover, #F5F5F5);
      transform: translateX(4px);
    }
  }

  &.status-failed {
    border-color: var(--color-error, #F44336);
    background-color: var(--color-error-light, #FFEBEE);

    .status-name {
      color: var(--color-error, #F44336);
    }
  }

  .status-icon {
    margin-right: 12px;
    display: flex;
    align-items: center;
    
    .v-icon {
      font-size: 16px;
    }

    &.pulsing {
      .v-icon {
        animation: spin 1s linear infinite;
      }
      opacity: 1;
      animation: pulse 2s ease-in-out infinite;
    }
  }

  .status-content {
    flex: 1;
    min-width: 0;

    .status-name-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
      }
    }

    .status-name {
      font-weight: 500;
      font-size: 16px;
      color: var(--color-text-primary, #333333);

      @media screen and (max-width: 600px) {
        margin-bottom: 4px;
      }
    }

    .status-info {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;

      @media screen and (max-width: 600px) {
        width: 100%;
        justify-content: space-between;
      }
    }

    .status-pill {
      display: inline-flex;
      align-items: center;
      padding: 2px 8px;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 500;
      white-space: nowrap;
      
      @media screen and (max-width: 600px) {
        padding: 4px 10px;
      }

      &.synced {
        background-color: var(--color-success, #4CAF50);
        color: white;

        .v-icon {
          color: white !important;
        }
      }
      
      &.failed {
        background-color: var(--color-error-light, #FFEBEE);
        color: var(--color-error, #F44336);
      }
      
      &.pending {
        background-color: var(--color-grey-light, #F5F5F5);
        color: var(--color-grey, #9E9E9E);
      }
      
      &.in-progress {
        background-color: var(--color-warning-light, #FFF3E0);
        color: var(--color-warning, #FF9800);
      }
    }

    .completion-status {
      font-size: 12px;
      color: var(--color-text-secondary, #666666);
      white-space: nowrap;
    }
  }

  .status-action {
    margin-left: 12px;
    opacity: 0.7;
    transition: opacity 0.2s ease;

    @media screen and (max-width: 600px) {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }

    .v-icon {
      font-size: 14px;
    }

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px) {
    position: relative;
    padding-right: 40px; // Make room for the action icon
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-text {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.status-name {
  &.pulsing-text {
    animation: pulse-text 2s ease-in-out infinite;
    color: var(--color-warning, #FF9800);
  }
}
</style> 