<template>
  <div class="step-container advisor">
    <v-form name="ClientAccountSelection" class="form">
      <v-container class="client-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight">
                What <strong>advisory rate</strong> would you like to use?
              </span>
            </h1>
          </v-col>
        </v-row>

        <!-- No Accounts -->
        <div class="alert" v-if="noAccountsChosen">
          <div class="pr-2">
            <span class="fa-icon left">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </div>
          <div>
            You must complete the Account Selection screen before choosing
            rates.
          </div>
        </div>

        <v-row class="justify-center" v-if="!noAccountsChosen">
          <v-col class="step-form-container checkbox-container">
            <v-radio-group
              v-model="advisoryRate"
              @change="$v.advisoryRate.$touch()"
              :error-messages="advisoryRateErrors"
              hide-details="auto"
            >
              <v-radio
                :ripple="false"
                class="v-faux-radio mb-4"
                :label="RATE_TYPES.FLAT"
                :value="RATE_TYPES.FLAT"
              >
              </v-radio>
              <div class="expander" v-show="isFlatRate">
                <v-row class="align-center percent-row">
                  <v-col class="question-text"> Choose your flat rate % </v-col>
                  <v-col class="d-flex justify-end align-center">
                    <rate-picker
                      v-model="flatPercent"
                      @update-number="updateFlatPercent"
                    ></rate-picker>
                  </v-col>
                </v-row>
                <v-row
                  class="align-center mb-4"
                  v-if="flatPercentErrors.length > 0"
                >
                  <v-col class="error-area text-right">{{
                    flatPercentErrors
                  }}</v-col>
                </v-row>
              </div>
              <v-radio
                :ripple="false"
                class="v-faux-radio mb-4"
                :label="RATE_TYPES.MANUAL"
                :value="RATE_TYPES.MANUAL"
              >
              </v-radio>
              <div class="expander" v-show="isManualRate">
                <div
                  v-for="(account, index) in clientOneAccounts"
                  :key="`One-${account.nickname}`"
                >
                  <v-row class="align-center percent-row">
                    <v-col class="question-text">
                      <div class="client-label">{{ clientOneName }}</div>
                      {{ account.name }} ({{ account.nickname }})
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <rate-picker
                        :value="account.manualPercent"
                        @update-number="
                          updateManualPercent($event, 'One', index)
                        "
                      ></rate-picker>
                    </v-col>
                  </v-row>
                  <v-row
                    class="align-center mb-4"
                    v-if="manualPercentErrors('One', index).length > 0"
                  >
                    <v-col class="error-area text-right">
                      {{ manualPercentErrors('One', index) }}
                    </v-col>
                  </v-row>
                </div>

                <div
                  v-for="(account, index) in clientTwoAccounts"
                  :key="`Two-${account.nickname}`"
                >
                  <v-row class="align-center percent-row">
                    <v-col class="question-text">
                      <div class="client-label">{{ clientTwoName }}</div>
                      {{ account.name }} ({{ account.nickname }})
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <rate-picker
                        :value="account.manualPercent"
                        @update-number="
                          updateManualPercent($event, 'Two', index)
                        "
                      ></rate-picker>
                    </v-col>
                  </v-row>
                  <v-row
                    class="align-center mb-4"
                    v-if="manualPercentErrors('Two', index).length > 0"
                  >
                    <v-col class="error-area text-right">
                      {{ manualPercentErrors('Two', index) }}
                    </v-col>
                  </v-row>
                </div>

                <div
                  v-for="(account, index) in jointAccounts"
                  :key="`Joint-${account.nickname}`"
                >
                  <v-row class="align-center percent-row">
                    <v-col class="question-text">
                      <div class="client-label">{{ jointName }}</div>
                      {{ account.name }} ({{ account.nickname }})
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <rate-picker
                        :value="account.manualPercent"
                        @update-number="
                          updateManualPercent($event, 'Joint', index)
                        "
                      ></rate-picker>
                    </v-col>
                  </v-row>
                  <v-row
                    class="align-center mb-4"
                    v-if="manualPercentErrors('Joint', index).length > 0"
                  >
                    <v-col class="error-area text-right">
                      {{ manualPercentErrors('Joint', index) }}
                    </v-col>
                  </v-row>
                </div>
              </div>
              
              <!-- Add Clear Selection Button -->
              <v-btn
                text
                color="primary"
                class="mt-4 clear-selection-btn"
                @click="clearAdvisoryRate"
                :disabled="!advisoryRate"
              >
                <v-icon left>fas fa-times</v-icon>
                Clear selection
              </v-btn>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <bottom-nav 
      v-on:go-prev="prev()" 
      v-on:go-next="next()"
      :has-skip="false"
    ></bottom-nav>
  </div>
</template>
<script>
import BottomNav from '@/components/common/BottomNav';
import { requiredIf } from 'vuelidate/lib/validators';
import AdvisorClientMixin from '@/common/mixins/advisor.client.mixin';
import { MENUS, DEFAULT_RATE } from '@/common/constants';
import RatePicker from '@/components/common/ValuePicker.vue';
const greaterThanEqualToZero = (value) => value >= 0;

export default {
  components: { BottomNav, RatePicker },
  name: 'AccountSelection',

  mixins: [AdvisorClientMixin],

  data: () => ({
    advisoryRate: null,
    flatPercent: DEFAULT_RATE,
    clientOneAccounts: [],
    clientTwoAccounts: [],
    jointAccounts: [],
  }),

  validations() {
    return {
      advisoryRate: {
        // Remove required validation to allow null value
        // required: requiredIf(() => !this.noAccountsChosen),
      },
      flatPercent: {
        required: requiredIf(() => this.isFlatRate),
        greaterThanEqualToZero,
      },
      clientOneAccounts: {
        $each: {
          manualPercent: {
            required: requiredIf(() => this.isManualRate),
            greaterThanEqualToZero,
          },
        },
      },
      clientTwoAccounts: {
        $each: {
          manualPercent: {
            required: requiredIf(() => this.isManualRate),
            greaterThanEqualToZero,
          },
        },
      },
      jointAccounts: {
        $each: {
          manualPercent: {
            required: requiredIf(() => this.isManualRate),
            greaterThanEqualToZero,
          },
        },
      },

      validationGroup: [
        'advisoryRate',
        'flatPercent',
        'clientOneAccounts',
        'clientTwoAccounts',
        'jointAccounts',
      ],
    };
  },

  computed: {
    /* Dynamic errors */
    advisoryRateErrors() {
      const errors = [];
      if (!this.$v.advisoryRate.$dirty) return errors;
      // !this.$v.advisoryRate.required && errors.push('A selection is required');
      return errors;
    },
    flatPercentErrors() {
      let errors = '';
      if (!this.$v.flatPercent.$dirty) return errors;
      if (!this.$v.flatPercent.greaterThanEqualToZero)
        errors = 'Percentage cannot be less than zero';
      return errors;
    },
    noAccountsChosen() {
      return (
        this.clientOneAccounts.length === 0 &&
        this.clientTwoAccounts.length === 0 &&
        this.jointAccounts.length === 0
      );
    },

    clientNames() {
      return this.isClientTwoNameComplete
        ? `${this.clientOneName}, ${this.clientTwoName}`
        : this.clientOneName;
    },

    clients() {
      const l = [this.clientOneName];
      if (this.isClientTwoNameComplete) l.push(this.clientTwoName);
      return l;
    },

    isFlatRate() {
      return this.advisoryRate === this.RATE_TYPES.FLAT;
    },

    isManualRate() {
      return this.advisoryRate === this.RATE_TYPES.MANUAL;
    },
    jointName() {
      return `${this.clientOneName} and ${this.clientTwoName}`;
    },
  },

  methods: {
    /* Initialize the Advisory Rate data for the view */
    initializeAdvisoryData() {
      this.advisoryRate = this.currentClient.rateType;
      const featureSelections = this.getAccountRateSelections();
      const findByName = (name) =>
        featureSelections.find((f) => f.name === name);

      // Client One Accounts
      let clientFeatures = findByName(this.clientOneName);
      this.clientOneAccounts = [];
      if (clientFeatures) {
        clientFeatures.features.forEach((f) => {
          this.clientOneAccounts.push({
            name: f.name,
            nickname: f.nickname,
            manualPercent: this.isFlatRate
              ? DEFAULT_RATE
              : f.rate || DEFAULT_RATE,
          });
          this.flatPercent = this.isFlatRate
            ? f.rate || DEFAULT_RATE
            : DEFAULT_RATE;
        });
      }

      // Client Two Accounts
      clientFeatures = findByName(this.clientTwoName);
      this.clientTwoAccounts = [];
      if (clientFeatures) {
        clientFeatures.features.forEach((f) => {
          this.clientTwoAccounts.push({
            name: f.name,
            nickname: f.nickname,
            manualPercent: this.isFlatRate
              ? DEFAULT_RATE
              : f.rate || DEFAULT_RATE,
          });
          this.flatPercent = this.isFlatRate
            ? f.rate || DEFAULT_RATE
            : DEFAULT_RATE;
        });
      }

      // Joint accounts
      clientFeatures = findByName(this.jointName);
      this.jointAccounts = [];
      if (clientFeatures) {
        clientFeatures.features.forEach((f) => {
          this.jointAccounts.push({
            name: f.name,
            nickname: f.nickname,
            manualPercent: this.isFlatRate
              ? DEFAULT_RATE
              : f.rate || DEFAULT_RATE,
          });
          this.flatPercent = this.isFlatRate
            ? f.rate || DEFAULT_RATE
            : DEFAULT_RATE;
        });
      }
    },

    /* Return errors related to manual percentages */
    manualPercentErrors(type, index) {
      let errors = '';
      const v =
        type === 'One'
          ? this.$v.clientOneAccounts
          : type === 'Two'
          ? this.$v.clientTwoAccounts
          : this.$v.jointAccounts;
      if (!v.$each[index].manualPercent.$dirty) return errors;
      if (!v.$each[index].manualPercent.greaterThanEqualToZero)
        errors = 'Percentage cannot be less than zero';
      return errors;
    },

    /* Update the Flat Advisory Rate */
    updateFlatPercent(number) {
      this.flatPercent = number;
      this.$v.flatPercent.$touch();
    },

    /* Update a Manual Advisory Rate for an account/client */
    updateManualPercent(number, type, index) {
      const account =
        type === 'One'
          ? this.clientOneAccounts
          : type === 'Two'
          ? this.clientTwoAccounts
          : this.jointAccounts;
      account[index].manualPercent = number;
      const v =
        type === 'One'
          ? this.$v.clientOneAccounts
          : type === 'Two'
          ? this.$v.clientTwoAccounts
          : this.$v.jointAccounts;
      v.$each[index].manualPercent.$touch();
    },

    /* Set the advisory rates selected for the working client */
    setAdvisoryRates() {
      // Set Client One Account Rates
      this.clientOneAccounts.forEach((a) => {
        let accountObj = this.clientOne.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.rate = this.isFlatRate
            ? this.flatPercent
            : a.manualPercent;
        }
      });

      // Set Client Two Account Rates
      this.clientTwoAccounts.forEach((a) => {
        let accountObj = this.clientTwo.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.rate = this.isFlatRate
            ? this.flatPercent
            : a.manualPercent;
        }
      });

      // Set Joint Account Rates on Client One and Client Two
      this.jointAccounts.forEach((a) => {
        let accountObj = this.clientOne.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.rate = this.isFlatRate
            ? this.flatPercent
            : a.manualPercent;
        }

        accountObj = this.clientTwo.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.rate = this.isFlatRate
            ? this.flatPercent
            : a.manualPercent;
        }
      });
    },

    /* Map form data to working client */
    mapData() {
      // Set the rate type explicitly to null when advisoryRate is null
      this.currentClient.rateType = this.advisoryRate;
      
      // Only set advisory rates on accounts if a rate type is selected
      if (this.advisoryRate) {
        this.setAdvisoryRates();
      }
    },

    /* Nav to Next Page in flow */
    next(route = null) {
      // Touch validation for any manually entered values
      this.$v.$touch();
      
      // If form has errors in manual inputs, don't proceed
      if (this.advisoryRate && this.$v.$invalid) {
        return;
      }
      
      // Map data to client and proceed to next page
      this.mapData();
      const to = route ? route.name : MENUS.ADVISOR.CLIENT.FIRM_ROLES.id;
      this.goNext(to);
    },

    /* Nav to Previous Page in flow */
    prev() {
      // Determine Previous page based on state
      // If no accounts have been chosen, go directly to Account Selection page
      // Otherwise, go to the Features Page
      this.goPrev(
        this.noAccountsChosen
          ? MENUS.ADVISOR.CLIENT.ACCOUNT_SELECTION.id
          : MENUS.ADVISOR.CLIENT.ACCOUNT_FEATURES.id,
      );

      /*
        // Non-intelligent nav
        this.goPrev(MENUS.ADVISOR.CLIENT.ACCOUNT_FEATURES.id);
        */
    },

    /* Legacy method - maintained for backwards compatibility but no longer directly called from UI */
    skip() {
      // Set null values for advisory rates
      this.advisoryRate = null;
      this.currentClient.rateType = null;
      
      // Clear rates for all accounts
      this.clientOneAccounts.forEach((a) => {
        let accountObj = this.clientOne.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.rate = null;
        }
      });

      // Clear rates for Client Two accounts if they exist
      this.clientTwoAccounts.forEach((a) => {
        let accountObj = this.clientTwo.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.rate = null;
        }
      });

      // Clear rates for joint accounts if they exist
      this.jointAccounts.forEach((a) => {
        let accountObj = this.clientOne.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.rate = null;
        }

        accountObj = this.clientTwo.accounts.find(
          (ca) => ca.label === a.nickname,
        );
        if (accountObj) {
          accountObj.rate = null;
        }
      });

      // Navigate to the next page
      const to = MENUS.ADVISOR.CLIENT.FIRM_ROLES.id;
      this.goNext(to);
    },

    /* Add new method to clear advisory rate selection */
    clearAdvisoryRate() {
      this.advisoryRate = null;
      
      // Clear rates for all accounts
      this.clientOneAccounts.forEach(a => {
        let accountObj = this.clientOne.accounts.find(ca => ca.label === a.nickname);
        if (accountObj) {
          accountObj.rate = null;
        }
      });
      
      this.clientTwoAccounts.forEach(a => {
        let accountObj = this.clientTwo.accounts.find(ca => ca.label === a.nickname);
        if (accountObj) {
          accountObj.rate = null;
        }
      });
      
      this.jointAccounts.forEach(a => {
        let accountObj = this.clientOne.accounts.find(ca => ca.label === a.nickname);
        if (accountObj) {
          accountObj.rate = null;
        }
        
        accountObj = this.clientTwo.accounts.find(ca => ca.label === a.nickname);
        if (accountObj) {
          accountObj.rate = null;
        }
      });
      
      // Update the client object with the cleared rates
      this.currentClient.rateType = null;
      
      // Touch the validation to update UI state
      this.$v.advisoryRate.$touch();
    },
  },

  created() {
    this.initializeAdvisoryData();
  },
};
</script>
<style lang="scss" scoped>
.expander {
  .row.percent-row {
    padding-bottom: 15px;
    padding-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--secondary-color);
  }

  .question-text {
    font-weight: 500;
  }

  .client-label {
    color: var(--color-text);
    font-size: 14px;
    font-weight: 400;
  }
}
.prepend {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
}
.checkbox-container {
  overflow: hidden;
}
.close {
  float: right;
  padding: 0;
}
</style>
