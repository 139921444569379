<template>
  <v-container class="client-step-container">
      <v-row class="mb-8">
        <v-col class="instructions d-flex align-center">
          <h1>
            <span class="headerlight">
              {{ question }}
            </span>
          </h1>
          <v-tooltip v-if="description" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="ml-6"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>fas fa-question-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ description }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            id="textarea"
            outlined
            required
            auto-grow
            :rows="2"
            :cols="200"
            hide-details="auto"
            class="my-textarea"
            v-model.trim="answer"
            :error-messages="answerErrors"
            @change="updateAnswer"
            @blur="$v.answer.$touch()"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>

import { required } from 'vuelidate/lib/validators';

import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import Themable from '@/common/mixins/themable.mixin';
import { EventService } from '@/services/event.service';


export default {
  name: 'InputText',
  props: {
    question: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: true
    },
    description: {
      type: String,
      required: false
    }
  },
  mixins: [InterviewClientMixin, Themable],
  validations: {
    answer: { required },
  },
  data() {
    return {
      answer: null
    }
  },
  methods: {
    updateAnswer() {
      EventService.emit('update-answer', this.answer);
    },
  },
  computed: {
    answerErrors() {
      const errors = [];
      if (!this.$v.answer.$dirty) return errors;
      !this.$v.answer.required && errors.push('Answer is required');
      return errors;
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .my-textarea textarea {
  padding: 10px !important;
}

.instructions {
  h1 {
    margin-bottom: 0;
  }
}
</style>