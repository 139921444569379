<template>
  <div class="template-editor">
    <div class="d-flex justify-space-between align-center mb-2">
      <label class="v-label required body-2 mb-0">Template Content</label>
      <div class="d-flex align-center">
        <v-btn-toggle
          v-model="currentEditorMode"
          mandatory
          dense
          class="editor-mode-toggle mr-3"
          aria-label="Editor mode selection"
        >
          <v-btn small value="wysiwyg" title="Simple Editor" aria-label="Switch to Simple Editor">
            <v-icon small class="mr-1">fas fa-edit</v-icon>
            <span class="btn-text">Simple</span>
          </v-btn>
          <v-btn small value="html" title="Advanced HTML Code Editor" aria-label="Switch to Advanced HTML Editor">
            <v-icon small class="mr-1">fas fa-code</v-icon>
            <span class="btn-text">Advanced</span>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    
    <!-- Show mode description based on selected mode -->
    <div class="editor-mode-description mb-3">
      <div v-show="currentEditorMode === 'wysiwyg'" class="simple-mode-description">
        <v-icon x-small color="primary" class="mr-1">fas fa-info-circle</v-icon>
        <span class="caption">Simple mode provides easy-to-use formatting tools, perfect for most users.</span>
      </div>
      <div v-show="currentEditorMode === 'html'" class="simple-mode-description">
        <v-icon x-small color="amber darken-3" class="mr-1">fas fa-info-circle</v-icon>
        <span class="caption"><strong>Advanced Mode:</strong> For users familiar with HTML. Incorrect HTML syntax may cause display issues.</span>
      </div>
    </div>
    
    <!-- Loading indicator -->
    <div v-if="isLoading" class="editor-loading-container">
      <v-progress-circular indeterminate color="primary" size="32"></v-progress-circular>
      <span class="ml-2 body-2">Loading editor...</span>
    </div>
    
    <!-- HTML Mode (Prism Editor) - Now "Advanced" -->
    <div v-if="currentEditorMode === 'html'" class="code-editor-container">
      <prism-editor
        v-model="localContent"
        :highlight="highlighter"
        line-numbers
        class="my-editor"
        :disabled="disabled"
        ref="prismEditor"
        aria-label="HTML Code Editor"
      ></prism-editor>
    </div>
    
    <!-- WYSIWYG Mode (CKEditor) - Now "Simple" -->
    <div v-else class="wysiwyg-editor-container">
      <ckeditor
        v-model="localContent"
        :editor="editor"
        :config="editorConfig"
        @ready="onEditorReady"
        :disabled="disabled"
        class="email-wysiwyg-editor"
        aria-label="Rich Text Editor"
      ></ckeditor>
    </div>
    
    <!-- Validation error message -->
    <div v-if="showValidationError && !localContent" class="error-text" role="alert">
      Template content is required
    </div>
  </div>
</template>

<script>
// Import Prism Editor
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';

// Import Prism syntax highlighting
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';

// Import CKEditor - Uncommented these lines
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PublicAssets from '@/common/mixins/publicasset.mixin';
import { ASSET_KEYS } from '@/common/constants';

export default {
  name: 'TemplateEditor',
  components: {
    PrismEditor,
    ckeditor: CKEditor.component
  },
  mixins: [PublicAssets],
  props: {
    value: {
      type: String,
      default: ''
    },
    editorMode: {
      type: String,
      default: 'wysiwyg',
      validator: function(value) {
        return ['html', 'wysiwyg'].indexOf(value) !== -1;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showValidationError: {
      type: Boolean,
      default: false
    },
    logoWidthConfig: {
      type: Number,
      default: 200
    },
    logoHeightConfig: {
      type: Number,
      default: 70
    }
  },
  data() {
    return {
      localContent: this.value,
      currentEditorMode: this.editorMode,
      editor: ClassicEditor,
      editorInstance: null,
      isLoading: true,
      editorConfig: {
        placeholder: 'Type or paste your email template content here...',
        toolbar: {
          items: [
            'heading', 'fontfamily', 'fontsize', 'fontColor', '|', 
            'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
            'insertTable', 'alignment', 'imageUpload', '|', 'undo', 'redo'
          ],
          shouldNotGroupWhenFull: true,
          viewportTopOffset: 30
        },
        ui: {
          // Ensure the toolbar is always visible
          viewportOffset: { top: 10, right: 10, bottom: 10, left: 10 }
        },
        // Custom plugins to handle placeholders
        extraPlugins: [
          function( editor ) {
            // Add special handling for placeholders
            editor.editing.view.document.on( 'paste', ( evt, data ) => {
              const text = data.dataTransfer.getData('text/plain');
              
              // Check if the pasted content contains placeholders
              if (text && text.includes('{{') && text.includes('}}')) {
                
                // Special handling for organization logo placeholder
                if (text.includes('{{organizationLogo}}')) {
                  // Define standard dimensions for organization logo
                  const logoWidth = 200;
                  const logoHeight = 70;
                  
                  // Get the company logo URL if available
                  const currentCompany = editor.sourceElement._app.$store.getters.currentCompany;
                  let logoUrl = '';
                  if (currentCompany && currentCompany.assets) {
                    const logo = currentCompany.assets.find(asset => asset.assetType === 'LOGO');
                    if (logo) logoUrl = logo.assetLocation;
                  }
                  
                  // Replace organization logo placeholder with properly formatted HTML
                  const logoSrc = logoUrl || '{{organizationLogo}}';
                  const formattedText = text.replace(/{{organizationLogo}}/g, 
                    `<div style="text-align: center; margin-bottom: 20px; padding: 10px;">
                      <img src="${logoSrc}" 
                           data-placeholder="{{organizationLogo}}" 
                           alt="Organization Logo" 
                           style="max-width: ${logoWidth}px; max-height: ${logoHeight}px; width: ${logoWidth}px; height: ${logoHeight}px; margin: 0 auto;"
                           width="${logoWidth}" 
                           height="${logoHeight}">
                    </div>`
                  );
                  
                  // Insert the formatted content
                  const viewFragment = editor.data.processor.toView(formattedText);
                  const modelFragment = editor.data.toModel(viewFragment);
                  editor.model.insertContent(modelFragment);
                } else {
                  // Handle other placeholders normally
                  const viewFragment = editor.data.processor.toView(text);
                  const modelFragment = editor.data.toModel(viewFragment);
                  editor.model.insertContent(modelFragment);
                }
                
                // Prevent the default paste handler
                evt.stop();
              }
            });
            
            // Add special handling for organizationLogo placeholder
            // This ensures it's always properly inserted in WYSIWYG mode
            editor.conversion.for('upcast').elementToElement({
              view: {
                name: 'img',
                attributes: {
                  src: /{{organizationLogo}}/
                }
              },
              model: (viewElement, { writer }) => {
                return writer.createElement('image', {
                  src: '{{organizationLogo}}',
                  alt: 'Organization Logo'
                });
              }
            });
          }
        ],
        // Disable the "Sticky toolbar" feature to keep it always at the top
        balloonToolbar: {
          items: []
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
          ]
        },
        fontFamily: {
          options: [
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif'
          ]
        },
        fontSize: {
          options: [
            'tiny',
            'small',
            'default',
            'medium',
            'large',
            'huge'
          ]
        },
        image: {
          styles: [
            'full',
            'alignLeft',
            'alignCenter',
            'alignRight'
          ],
          resizeOptions: [
            {
              name: 'imageResize:original',
              label: 'Original',
              value: null
            },
            {
              name: 'imageResize:50',
              label: '50%',
              value: '50'
            },
            {
              name: 'imageResize:75',
              label: '75%',
              value: '75'
            }
          ],
          toolbar: [
            'imageStyle:full',
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'imageTextAlternative'
          ]
        }
      }
    };
  },
  computed: {
    organizationLogoUrl() {
      // Get the current company's logo URL from the store
      if (!this.$store.getters.currentCompany || !this.$store.getters.currentCompany.assets) return '';
      const logo = this.$store.getters.currentCompany.assets.find(
        (asset) => asset.assetType === ASSET_KEYS.LOGO
      );
      return logo ? logo.assetLocation : '';
    },
    logoWidth() {
      return this.logoWidthConfig;
    },
    logoHeight() {
      return this.logoHeightConfig;
    }
  },
  watch: {
    value(newVal) {
      if (newVal !== this.localContent) {
        this.localContent = newVal;
      }
    },
    localContent(newVal) {
      this.$emit('input', newVal);
    },
    editorMode(newVal) {
      this.currentEditorMode = newVal;
    },
    currentEditorMode(newMode, oldMode) {
      this.$emit('update:editorMode', newMode);
      
      // If switching modes, ensure content is properly converted
      this.$nextTick(() => {
        try {
          if (newMode === 'html' && oldMode === 'wysiwyg') {
            // When switching from WYSIWYG to HTML, ensure HTML is properly formatted
            if (this.editorInstance) {
              // Get the current data from CKEditor
              const formattedHtml = this.editorInstance.getData();
              // Update localContent with properly formatted HTML
              this.localContent = formattedHtml;
            }
            
            // Focus on the prism editor
            if (this.$refs.prismEditor) {
              this.$refs.prismEditor.focus();
            }
          } else if (newMode === 'wysiwyg' && oldMode === 'html') {
            // When switching from HTML to WYSIWYG, ensure content is valid
            // The CKEditor will handle HTML validation automatically
            
            // After content is set, focus the WYSIWYG editor
            this.$nextTick(() => {
              if (this.editorInstance) {
                this.editorInstance.editing.view.focus();
              }
            });
          }
        } catch (error) {
          this.$emit('editor-error', { type: 'mode-switch', error, oldMode, newMode });
        }
      });
    }
  },
  mounted() {
    // Set initial loading state
    this.isLoading = true;
    
    // Set up keyboard shortcuts
    window.addEventListener('keydown', this.handleKeyboardShortcuts);
    
    // Hide loading after component is mounted
    this.$nextTick(() => {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    });
  },
  beforeDestroy() {
    // Remove event listeners
    window.removeEventListener('keydown', this.handleKeyboardShortcuts);
    
    // Clean up CKEditor instance to prevent memory leaks
    if (this.editorInstance) {
      this.editorInstance.destroy()
        .catch(error => {
          this.$emit('editor-error', { type: 'editor-cleanup', error });
        });
    }
  },
  methods: {
    highlighter(code) {
      // Use the default Prism highlighter for HTML
      return window.Prism.highlight(
        code,
        window.Prism.languages.markup,
        'html'
      );
    },
    onEditorReady(editor) {
      try {
        this.editorInstance = editor;
        
        // Make sure the toolbar is visible from the start
        if (editor && editor.ui && editor.ui.view && editor.ui.view.toolbar) {
          // Set the toolbar to be visible
          editor.ui.view.toolbar.element.style.display = 'flex';
          
          // Add ARIA attributes for better accessibility
          if (editor.ui.view.toolbar.element) {
            editor.ui.view.toolbar.element.setAttribute('role', 'toolbar');
            editor.ui.view.toolbar.element.setAttribute('aria-label', 'Formatting toolbar');
          }
          
          // Focus the editor to make the toolbar visible
          setTimeout(() => {
            editor.editing.view.focus();
            // Hide the loading indicator when editor is ready
            this.isLoading = false;
          }, 100);
        }
        
        // Set up loading indicators
        this.$emit('editor-ready', { editor });
      } catch (error) {
        this.isLoading = false;
        this.$emit('editor-error', { type: 'editor-initialization', error });
      }
    },
    focus() {
      if (this.currentEditorMode === 'html') {
        if (this.$refs.prismEditor) {
          this.$refs.prismEditor.focus();
        }
      } else {
        if (this.editorInstance) {
          this.editorInstance.editing.view.focus();
        }
      }
    },
    refreshEditor() {
      // Force the editor to refresh with the current content
      if (this.currentEditorMode === 'wysiwyg' && this.editorInstance) {
        // Get the current content
        const currentContent = this.localContent;
        
        // Set the content again to force a refresh
        this.$nextTick(() => {
          this.editorInstance.setData(currentContent);
          
          // Focus the editor after refresh
          this.editorInstance.editing.view.focus();
        });
      } else if (this.currentEditorMode === 'html') {
        // For HTML mode, just force a re-render
        this.$nextTick(() => {
          const textarea = this.$el.querySelector('textarea');
          if (textarea) {
            textarea.focus();
          }
        });
      }
    },
    insertPlaceholder(placeholder) {
      try {
        const placeholderText = `{{${placeholder}}}`;
        
        // Determine the type of placeholder
        const isImagePlaceholder = this.isImagePlaceholder(placeholder);
        const isOrgLogo = this.isOrganizationLogo(placeholder);
        
        // Handle based on editor mode
        if (this.currentEditorMode === 'wysiwyg') {
          this.insertPlaceholderInWysiwyg(placeholder, placeholderText, isImagePlaceholder, isOrgLogo);
        } else {
          this.insertPlaceholderInHtml(placeholder, placeholderText, isImagePlaceholder, isOrgLogo);
        }
        
        // Emit event for organization logo insertion
        if (isOrgLogo) {
          this.$emit('placeholder-inserted', {
            type: 'success',
            placeholder: 'Organization Logo'
          });
        }
      } catch (error) {
        // Handle errors gracefully
        this.$emit('editor-error', { 
          type: 'placeholder-insertion', 
          error, 
          placeholder 
        });
        
        // Fallback: append to the end if insertion fails
        this.appendPlaceholderToEnd(placeholder);
      }
    },
    
    // Helper methods for placeholder insertion
    isImagePlaceholder(placeholder) {
      const lowerPlaceholder = placeholder.toLowerCase();
      return lowerPlaceholder.includes('logo') || lowerPlaceholder.includes('image');
    },
    
    isOrganizationLogo(placeholder) {
      const cleanPlaceholder = placeholder.replace(/[{}]/g, '').toLowerCase();
      return cleanPlaceholder === 'organizationlogo';
    },
    
    // Insert placeholder in WYSIWYG editor (CKEditor)
    insertPlaceholderInWysiwyg(placeholder, placeholderText, isImagePlaceholder, isOrgLogo) {
      if (!this.editorInstance) return;
      
      try {
        // Define standard dimensions for organization logo
        const logoWidth = this.logoWidth;
        const logoHeight = this.logoHeight;
        
        if (isImagePlaceholder) {
          if (isOrgLogo) {
            // For organization logo in WYSIWYG mode, create a properly formatted image element
            // that will display correctly in CKEditor
            const logoSrc = this.organizationLogoUrl || placeholderText;
            
            // Create HTML that exactly matches what the preview dialog uses
            const logoHtml = `<div style="text-align: center; margin-bottom: 20px; padding: 10px;">
              <img src="${logoSrc}" 
                   data-placeholder="${placeholderText}" 
                   alt="Organization Logo" 
                   style="max-width: ${logoWidth}px; max-height: ${logoHeight}px; width: ${logoWidth}px; height: ${logoHeight}px; margin: 0 auto; display: inline-block;"
                   width="${logoWidth}" 
                   height="${logoHeight}">
            </div>`;
            
            // Insert the HTML directly into the editor
            const viewFragment = this.editorInstance.data.processor.toView(logoHtml);
            const modelFragment = this.editorInstance.data.toModel(viewFragment);
            this.editorInstance.model.insertContent(modelFragment);
            
            // Force a refresh to ensure proper rendering
            this.$nextTick(() => {
              this.refreshEditor();
            });
          } else {
            // For other logo/image placeholders, insert as image element
            const imageElement = this.editorInstance.model.change(writer => {
              return writer.createElement('image', {
                src: placeholderText,
                alt: placeholder,
                style: `max-width: ${logoWidth}px; max-height: ${logoHeight}px; width: ${logoWidth}px; height: ${logoHeight}px;`,
                width: String(logoWidth),
                height: String(logoHeight)
              });
            });
            
            this.editorInstance.model.insertContent(imageElement);
          }
        } else {
          // For regular placeholders, insert as styled text
          this.editorInstance.model.change(writer => {
            const placeholderTextNode = writer.createText(placeholderText, {
              bold: true,
              fontSize: 'small',
              fontColor: '#1e3b70' 
            });
            
            this.editorInstance.model.insertContent(placeholderTextNode);
          });
        }
        
        // Focus the editor after insertion
        this.editorInstance.editing.view.focus();
      } catch (error) {
        throw new Error(`Error inserting placeholder in CKEditor: ${error.message}`);
      }
    },
    
    // Insert placeholder in HTML editor (Prism Editor)
    insertPlaceholderInHtml(placeholder, placeholderText, isImagePlaceholder, isOrgLogo) {
      const editor = this.$refs.prismEditor;
      if (!editor || !editor.$el) return;
      
      const textarea = editor.$el.querySelector('textarea');
      if (!textarea || typeof textarea.selectionStart !== 'number') return;
      
      try {
        // Save cursor position
        const cursorPosition = textarea.selectionStart;
        
        // Get the editor container and save its scroll position
        const editorContainer = editor.$el.querySelector('.prism-editor__container');
        const scrollPosition = editorContainer ? editorContainer.scrollTop : 0;
        
        // Define standard dimensions - moved from original method
        const logoWidth = 200;
        const logoHeight = 70;
        
        // Prepare content to insert based on placeholder type
        let contentToInsert = placeholderText;
        
        if (isImagePlaceholder) {
          if (isOrgLogo) {
            // Special handling for organization logo
            const logoSrc = this.organizationLogoUrl || placeholderText;
            contentToInsert = this.getFormattedLogoHtml(logoSrc, placeholderText, logoWidth, logoHeight);
          } else {
            // Normal handling for other image placeholders
            contentToInsert = `<img src="${placeholderText}" alt="${placeholder}" style="max-width: 200px; max-height: 70px;" width="200" height="70">`;
          }
        }
        
        // Insert at cursor position
        const currentContent = this.localContent || '';
        this.localContent = 
          currentContent.substring(0, cursorPosition) + 
          contentToInsert + 
          currentContent.substring(cursorPosition);
        
        // Move cursor position after the inserted content
        this.$nextTick(() => {
          // Restore scroll position and focus
          if (editorContainer) {
            editorContainer.scrollTop = scrollPosition;
          }
          
          textarea.focus();
          
          // Set cursor position after the inserted content
          const newPosition = cursorPosition + contentToInsert.length;
          textarea.setSelectionRange(newPosition, newPosition);
        });
      } catch (error) {
        throw new Error(`Error inserting placeholder in HTML editor: ${error.message}`);
      }
    },
    
    // Generate the HTML for a formatted logo
    getFormattedLogoHtml(logoSrc, placeholderText, width = this.logoWidth, height = this.logoHeight) {
      return `<div style="text-align: center; margin-bottom: 20px; padding: 10px;">
        <img src="${logoSrc}" 
             data-placeholder="${placeholderText}" 
             alt="Organization Logo" 
             style="max-width: ${width}px; max-height: ${height}px; width: ${width}px; height: ${height}px; margin: 0 auto; display: inline-block;"
             width="${width}" 
             height="${height}">
      </div>`;
    },
    
    // Fallback method when other insertion methods fail
    appendPlaceholderToEnd(placeholder) {
      const placeholderText = `{{${placeholder}}}`;
      const isOrgLogo = this.isOrganizationLogo(placeholder);
      
      if (isOrgLogo) {
        // For organization logo, use properly formatted img tag even in fallback
        const logoSrc = this.organizationLogoUrl || placeholderText;
        const formattedLogo = this.getFormattedLogoHtml(logoSrc, placeholderText, 200, 70);
        this.localContent = (this.localContent || '') + formattedLogo;
      } else {
        // For other placeholders, use simple text insertion
        this.localContent = (this.localContent || '') + placeholderText;
      }
    },
    // Handle keyboard shortcuts
    handleKeyboardShortcuts(event) {
      // Only process if this component is focused
      if (!this.$el.contains(document.activeElement)) return;
      
      // Ctrl+S or Cmd+S: Trigger save event
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        this.$emit('save-requested');
      }
      
      // Ctrl+Shift+M or Cmd+Shift+M: Toggle editor mode
      if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'm') {
        event.preventDefault();
        this.currentEditorMode = this.currentEditorMode === 'wysiwyg' ? 'html' : 'wysiwyg';
      }
    }
  }
};
</script>

<style scoped>
/* Code Editor Styles */
.code-editor-container {
  position: relative;
  margin-bottom: 8px;
}

.template-editor .v-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
  display: block;
}

.template-editor .required::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.error-text {
  color: #ff5252;
  font-size: 12px;
  margin-top: 4px;
}

/* Prism Editor Styles */
.my-editor {
  background-color: #2d2d2d;
  color: #ccc;
  font-family: monospace;
  border-radius: 4px;
  border: 1px solid #ccc;
  height: 300px;
}

/* Editor Mode Toggle Styles */
.editor-mode-toggle {
  background-color: #f5f7f9;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.editor-mode-toggle .v-btn {
  height: 32px !important;
  min-width: 100px !important;
  box-shadow: none !important;
}

.editor-mode-toggle .btn-text {
  font-size: 12px;
  margin-right: 4px;
}

.editor-mode-toggle .v-btn.v-item--active {
  background-color: #1e3b70 !important;
}

.editor-mode-toggle .v-btn.v-item--active .v-icon,
.editor-mode-toggle .v-btn.v-item--active .btn-text {
  color: white !important;
}

.editor-mode-description {
  border-radius: 4px;
}

.simple-mode-description {
  padding: 4px 8px;
  background-color: rgba(30, 59, 112, 0.05);
  border-radius: 4px;
}

.advanced-mode-description {
  margin-top: 4px;
}

/* CKEditor Styles */
.wysiwyg-editor-container {
  position: relative;
  margin-bottom: 16px;
}

.email-wysiwyg-editor {
  min-height: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ck-editor__editable {
  min-height: 300px !important;
}

/* Fix toolbar styles - these need to be global */
</style>

<style>
/* Global CKEditor toolbar styles */
.ck.ck-toolbar {
  border: 1px solid #ddd !important;
  border-radius: 4px 4px 0 0 !important;
  background-color: #f8f9fa !important;
}

.ck.ck-toolbar .ck-toolbar__items {
  flex-wrap: wrap !important;
}

.ck.ck-editor__main {
  height: 100% !important;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
  border: 1px solid #ddd !important;
  border-top: none !important;
  border-radius: 0 0 4px 4px !important;
  min-height: 300px !important;
}

.ck.ck-button {
  padding: 4px 8px !important;
}

/* This ensures the sticky panel content is always visible */
.ck-sticky-panel__content {
  position: static !important;
  z-index: 1 !important;
}

/* Force the toolbar to be visible */
.ck-editor__top {
  display: block !important;
}

/* Ensure toolbar does not become hidden */
.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
  position: static !important;
  top: 0 !important;
  width: 100% !important;
}

/* Better visibility for dropdown panels */
.ck.ck-dropdown__panel {
  max-height: 300px !important;
  overflow-y: auto !important;
  z-index: 999 !important;
}

/* Ensure buttons are properly spaced */
.ck.ck-toolbar > .ck-toolbar__items > * {
  margin-right: 2px !important;
}
</style>

<style>
/* Add global styles for email-template-img to be accessible from all components */
.email-template-img {
  max-width: 250px !important;
  max-height: 200px !important;
  height: auto !important;
  object-fit: contain !important;
}
</style>

<style scoped>
/* Add loading indicator styles */
.editor-loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
  border-radius: 4px;
}

.my-editor {
  position: relative;
  min-height: 300px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.4;
  background-color: #f5f7f9;
}

.email-wysiwyg-editor {
  position: relative;
  min-height: 300px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.error-text {
  color: #ff5252;
  font-size: 12px;
  margin-top: 4px;
}

/* Apply focus styles for better keyboard navigation */
:deep(.ck-focused) {
  outline: 2px solid #1e88e5 !important;
  box-shadow: 0 0 0 2px rgba(30, 136, 229, 0.2) !important;
}

:deep(.ck-editor__editable) {
  min-height: 300px !important;
}
</style> 