<template>
  <v-dialog v-model="dialogVisible" max-width="500px" persistent @input="updateVisibility">
    <v-card>
      <v-card-title class="headline">Delete Email Template</v-card-title>
      <v-card-text>
        <p>Are you sure you want to delete this email template?</p>
        <p class="font-weight-medium mt-2" v-if="templateName">
          "{{ templateName }}"
        </p>
        <p class="caption mt-4 red--text">This action cannot be undone.</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn color="error" text @click="confirmDelete">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteConfirmationDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    templateName: {
      type: String,
      default: ''
    },
    templateId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: this.visible
    };
  },
  watch: {
    visible(newVal) {
      this.dialogVisible = newVal;
    }
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
    confirmDelete() {
      this.$emit('delete', this.templateId);
    },
    updateVisibility(value) {
      this.$emit('update:visible', value);
    }
  }
};
</script>

<style scoped>
.headline {
  font-size: 1.25rem !important;
  font-weight: 600;
  color: #1e3b70;
  background-color: #f5f7f9;
  padding: 16px 24px !important;
}
</style> 