<template>
  <div class="step-container client">
    <client-instructions></client-instructions>
    <div>
      <v-form name="CustomQuestionForm" class="form">
        <v-container class="client-step-container">
          <div v-if="!formHidden">
            <div v-if="customQuestion" class="container">
              <form-control
                ref="formControl"
                :required="customQuestion.required"
                :control="customQuestion.type"
                :question="customQuestion.question"
                :options="customQuestion.options"
                :description="customQuestion.description"
              ></form-control>
            </div>
            <div v-else>
              <p>Loading question...</p>
            </div>
            <div class="skip-for-now">
              <a @click="skip">Skip for now</a>
            </div>
          </div>
          <data-submitted-message
            :submitted="isCustomQuestionComplete"
            v-if="formHidden"
            v-on:hide-form="toggleFormState"
          />
        </v-container>
      </v-form>
    </div>
    <help-drawer v-model="showHelp" :question="customQuestion"></help-drawer>
    <div>
      <bottom-nav
        :hasPrevious="!isFirstPage"
        v-on:go-next="next"
        v-on:go-prev="prev"
        v-if="!navDisabled"
      ></bottom-nav>
    </div>
  </div>
</template>

<script>
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import BottomNav from '@/components/common/BottomNav';
import { MENUS } from '@/common/constants';
import DataSubmittedMessage from '@/components/client/DataSubmittedMessage';
import HelpDrawer from '@/components/HelpDrawerComponent';
import { required } from 'vuelidate/lib/validators';
import Themable from '@/common/mixins/themable.mixin';
import ClientInstructions from '@/components/client/dialog/InterviewInstructions';
import FormControl from "@/components/common/FormControl.vue";
import EventListener from '@/common/mixins/eventlistener.mixin';

export default {
  name: 'CustomQuestions',
  props: {
    questionId: String,
  },
  components: {
    BottomNav,
    ClientInstructions,
    HelpDrawer,
    DataSubmittedMessage,
    FormControl
  },
  mixins: [InterviewClientMixin, Themable, EventListener],
  validations: {
    answer: { required },
  },
  data() {
    return {
      interviewData: null,
      customQuestion: null,
      answer: null,
      defaultPreviousRoute:
        this.lastBeneficiaryRoute || MENUS.CLIENT.BENEFICIAL_INTEREST.id,
      defaultNextRoute: MENUS.CLIENT.FINISH.id,
      navDisabled: false,
      showHelp: false,
      formHidden: false,
    };
  },
  computed: {
    answerErrors() {
      const errors = [];
      if (!this.$v.answer.$dirty) return errors;
      !this.$v.answer.required && errors.push('Answer is required');
      return errors;
    },
    isFirstPage() {
      return this.getCustomQuestionPageIndex(this.questionId) === 0;
    },
  },
  mounted() {
    this.listen('update-answer', this.handleUpdateAnswer);
  },
  created() {
    this.formHidden = this.isCustomQuestionComplete;

    this.fetchInterviewData();
  },
  methods: {
    toggleCustomQuestionFormState(hide) {
      this.toggleFormState(hide);
      this.formHidden = hide;
      this.setNavDisabled();
    },
    /* Nav to Next Page in flow */
    next() {
      const formControlComponent = this.$refs.formControl;

      const nextRoute =
        this.getNextCustomQuestionRoute(this.questionId) ||
        this.defaultNextRoute;

      if (formControlComponent && typeof formControlComponent.validate === 'function') {
        const isValid = formControlComponent.validate();
        if (!isValid) {
          return;
        }
      }

      if (!this.formHidden) {
        this.$v.$touch();
        if (this.$v.$invalid) return;
      } else if (!this.answer) {
        return this.goNext(nextRoute);
      }

      const data = {
        question: this.customQuestion,
        answer: this.answer,
      };

      this.completeStep(MENUS.CLIENT.CUSTOM_QUESTIONS.id, data).then(() => {
        this.goNext(nextRoute);
      });
    },

    /* Take action from leave warning dialog */
    takeDialogAction(decision) {
      // Assuming you have a dialog component and a way to take a decision
      if (decision === 'save') {
        this.leave();
      } else if (decision === 'skip') {
        this.skip();
      }
    },

    /* Nav to Previous Page in flow */
    prev() {
      const prevRoute =
        this.getPreviousCustomQuestionRoute(this.questionId) ||
        this.defaultPreviousRoute;

      this.goPrev(prevRoute);
    },

    /* Skip to next Page in flow */
    skip() {
      const nextRoute =
        this.getNextCustomQuestionRoute(this.questionId) ||
        this.defaultNextRoute;
      this.goNext(nextRoute);
    },

    fetchInterviewData() {
      this.locateCustomQuestion();
    },

    locateCustomQuestion() {
      this.customQuestion = this.getCustomQuestion(this.questionId);
    },

    toggleHelp() {
      this.showHelp = !this.showHelp;
    },
    handleUpdateAnswer(value) {
      this.answer = value
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .my-textarea textarea {
  padding: 10px !important;
}
</style>
