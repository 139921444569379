<template>
  <div class="email-templates-container">
    <!-- Page Header with Title and Description -->
    <div class="page-header">
      <h1 class="page-title">Email Templates</h1>
      <p class="page-description">
        Manage your organization's email templates. Create, edit, and control template availability.
        <strong>Active templates</strong> are automatically used by the system when needed for email communications.
        <strong>Inactive templates</strong> are stored in the system but not used.
      </p>
    </div>

    <!-- Search and Add Template Bar -->
    <div class="action-bar">
      <div class="search-wrapper">
        <v-text-field
          v-model.trim="search"
          placeholder="Search templates..."
          prepend-inner-icon="fas fa-search fa-sm"
          clearable
          hide-details
          single-line
          class="search-input"
          dense
        >
        </v-text-field>
      </div>
      <v-spacer></v-spacer>
      <a 
        class="btn" 
        color="primary" 
        dark 
        @click="openAddTemplateDialog"
        v-if="canManageEmailTemplates"
      >
        Add Template
        <span class="fa-icon"><i class="fas fa-plus-circle"></i></span>
      </a>
    </div>
    
    <!-- Table Section -->
    <v-data-table
      class="responsive-table email-template-table"
      :headers="templateHeaders"
      :items="templatesSearch"
      :items-per-page="limit"
      :page.sync="page"
      :server-items-length="totalItems"
      @pagination="handlePagination"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 25, 50],
        'show-first-last-page': true
      }"
    >
      <template v-slot:item.name="{ item }">
        <div class="template-name">{{ item.emailTemplateName }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <v-chip small outlined color="primary" class="template-type-chip">
          {{ getEmailTypeLabel(item.emailTemplateType) }}
        </v-chip>
      </template>

      <!-- Status column with toggle switch -->
      <template v-slot:item.status="{ item }">
        <div class="status-container">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-flex align-center">
                <v-switch
                  v-model="item.isActive"
                  @change="toggleTemplateStatus(item)"
                  :color="item.isActive ? 'success' : 'grey'"
                  dense
                  hide-details
                  class="status-switch ma-0 pa-0"
                  :disabled="!canManageEmailTemplates || loading || shouldDisableSwitch(item)"
                ></v-switch>
                <span :class="['status-text', item.isActive ? 'success--text' : 'grey--text']">
                  {{ item.isActive ? 'Active' : 'Inactive' }}
                </span>
                <v-icon 
                  v-if="showWarningIcon(item)" 
                  x-small 
                  color="error" 
                  class="ml-2"
                >
                  fas fa-exclamation-circle
                </v-icon>
              </div>
            </template>
            <span>
              <strong>Active:</strong> Automatically used by the system when needed<br>
              <strong>Inactive:</strong> Stored but not used by the system
              <template v-if="showWarningIcon(item)">
                <br><br><span class="error--text"><strong>Note:</strong> There is already an active template of this type. Only one template per type can be active.</span>
              </template>
            </span>
          </v-tooltip>
        </div>
      </template>

      <!-- Slot for actions like edit and delete -->
      <template v-slot:item.actions="{ item }">
        <div class="actions-container">
          <a class="p-0" @click="editTemplate(item)" v-if="canManageEmailTemplates">
            <v-icon small class="mr-8"> fas fa-pencil </v-icon>
          </a>
          <a class="p-0" @click="confirmDeleteTemplate(item)" v-if="canManageEmailTemplates">
            <v-icon small> fas fa-trash </v-icon>
          </a>
        </div>
      </template>

      <template v-slot:no-data>
        <div class="no-data">
          No email templates found.
          <a @click="openAddTemplateDialog" v-if="canManageEmailTemplates">Add a new template.</a>
        </div>
      </template>
      
      <template v-slot:progress>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </template>
    </v-data-table>

    <!-- Add/Edit Template Dialog -->
    <template-dialog
      :visible.sync="templateDialog"
      :template="editedTemplate"
      :is-edit-mode="editMode"
      :email-types="emailTypes"
      :loading-email-types="loadingEmailTypes"
      :existing-templates="templatesSearch"
      @save="saveTemplate"
      @notification="showNotification"
    ></template-dialog>

    <!-- Delete Confirmation Dialog -->
    <delete-confirmation-dialog
      :visible.sync="deleteDialog"
      :template-name="templateToDelete ? templateToDelete.emailTemplateName : ''"
      :template-id="templateToDelete ? templateToDelete.id : ''"
      @delete="deleteTemplate"
    ></delete-confirmation-dialog>

    <!-- Preview Dialog -->
    <template-preview-dialog
      :visible.sync="previewDialog"
      :content="editedTemplate.emailTemplateContent"
      :template-name="editedTemplate.emailTemplateName"
      :template-type="getEmailTypeLabel(editedTemplate.emailTemplateType)"
      :placeholders="availablePlaceholders"
    ></template-preview-dialog>

  </div>
</template>

<script>
import { NOTIFICATION_TYPES, NotificationService } from '@/services/notification.service';
import { PermissionService, PERMISSIONS } from '@/services/permission.service';
import {
  GET_EMAIL_TEMPLATES,
  ADD_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  GET_EMAIL_TYPES,
} from '@/stores/actions.type';

import TemplateDialog from './TemplateDialog.vue';
import DeleteConfirmationDialog from './DeleteConfirmationDialog.vue';
import TemplatePreviewDialog from './TemplatePreviewDialog.vue';

export default {
  name: 'EmailTemplatesTable',
  components: {
    TemplateDialog,
    TemplatePreviewDialog,
    DeleteConfirmationDialog
  },
  data() {
    return {
      search: '',
      page: 1,
      limit: 10,
      totalItems: 0,
      loading: false,
      loadingEmailTypes: false,
      templateDialog: false,
      deleteDialog: false,
      editMode: false,
      templatesSearch: [],
      templateHeaders: [
        { text: 'Template Name', value: 'name', width: '30%' },
        { text: 'Template Type', value: 'type', width: '30%' },
        { text: 'Availability Status', value: 'status', width: '20%' },
        { text: 'Actions', value: 'actions', sortable: false, width: '20%', align: 'end' },
      ],
      editedTemplate: {
        emailTemplateName: '',
        emailTemplateType: '',
        emailTemplateContent: '',
        isActive: true,
      },
      defaultTemplate: {
        emailTemplateName: '',
        emailTemplateType: '',
        emailTemplateContent: '',
        isActive: true,
      },
      templateToDelete: null,
      emailTypes: [],
      editorMode: 'html',
      templateFormSubmitted: false,
      previewDialog: false,
      availablePlaceholders: []
    };
  },
  computed: {
    canManageEmailTemplates() {
      return PermissionService.hasPermission(PERMISSIONS.COMPANY.MANAGE_EMAIL_TEMPLATES);
    }
  },
  methods: {
    getEmailTypeLabel(emailType) {
      const type = this.emailTypes.find(t => t.emailType === emailType);
      return type ? type.label : emailType;
    },
    handlePagination({ page, itemsPerPage }) {
      this.page = page;
      this.limit = itemsPerPage;
      this.fetchTemplates();
    },
    fetchTemplates() {
      this.loading = true;
      this.$store
        .dispatch(GET_EMAIL_TEMPLATES, {
          page: this.page,
          limit: this.limit,
          search: this.search,
        })
        .then((response) => {
          console.log('API Response:', response);
          if (response.result && response.result.length > 0) {
            console.log('Template sample:', response.result[0]);
            
            // Ensure all templates have the isActive property
            this.templatesSearch = response.result.map(template => {
              // If isActive is undefined, default to true
              if (template.isActive === undefined) {
                template.isActive = true;
              }
              return template;
            });
          } else {
            this.templatesSearch = [];
          }
          
          this.totalItems = response.totalResults;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let errorMessage = 'Error fetching email templates';
          if (error && error.message === 'Current company not found') {
            errorMessage = 'Unable to fetch email templates: Company information not available';
          }
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error!',
            message: errorMessage,
          });
        });
    },
    fetchEmailTypes() {
      this.loadingEmailTypes = true;
      return this.$store
        .dispatch(GET_EMAIL_TYPES)
        .then((response) => {
          this.emailTypes = response.emailTypes || [];
          this.loadingEmailTypes = false;
          return response;
        })
        .catch((error) => {
          console.log('error', error);
          this.loadingEmailTypes = false;
          let errorMessage = 'Error fetching email types';
          if (error && error.message === 'Current company not found') {
            errorMessage = 'Unable to fetch email types: Company information not available';
          }
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error!',
            message: errorMessage,
          });
          return Promise.reject(error);
        });
    },
    openAddTemplateDialog() {
      if (!this.canManageEmailTemplates) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Permission Denied',
          message: 'You do not have permission to add email templates.',
        });
        return;
      }
      
      this.editMode = false;
      this.editedTemplate = { ...this.defaultTemplate };
      this.templateDialog = true;
      if (this.emailTypes.length === 0) {
        this.fetchEmailTypes();
      }
    },
    editTemplate(template) {
      if (!this.canManageEmailTemplates) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Permission Denied',
          message: 'You do not have permission to edit email templates.',
        });
        return;
      }
      
      // Log the entire template object to see its structure
      console.log('Original template object:', template);
      console.log('Template keys:', Object.keys(template));
      
      // Set edit mode before copying the template to prevent content clearing
      this.editMode = true;
      
      // Make a deep copy of the template
      this.editedTemplate = JSON.parse(JSON.stringify(template));
      
      // Ensure the ID is set correctly - it might be in id or _id
      if (template.id && !this.editedTemplate._id) {
        this.editedTemplate._id = template.id;
      } else if (template._id && !this.editedTemplate.id) {
        this.editedTemplate.id = template._id;
      }
      
      console.log('Edited template object:', this.editedTemplate);
      console.log('Edited template ID (_id):', this.editedTemplate._id);
      console.log('Edited template ID (id):', this.editedTemplate.id);
      console.log('Template content:', this.editedTemplate.emailTemplateContent);
      
      this.templateDialog = true;
      
      if (this.emailTypes.length === 0) {
        this.fetchEmailTypes();
      }
    },
    saveTemplate(template) {
      if (!this.canManageEmailTemplates) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Permission Denied',
          message: 'You do not have permission to save email templates.',
        });
        return;
      }
      
      if (!template.emailTemplateName || 
          !template.emailTemplateType || 
          !template.emailTemplateContent) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Validation Error',
          message: 'Please fill in all required fields.',
        });
        return;
      }
      
      // Check if we're activating a template that has a type with an already active template
      if (template.isActive && !this.editMode) {
        const existingActiveTemplate = this.templatesSearch.find(
          t => t.emailTemplateType === template.emailTemplateType && t.isActive === true
        );
        
        if (existingActiveTemplate) {
          // Force the new template to be inactive - no confirmation dialog
          template.isActive = false;
          
          // Inform the user
          NotificationService.alert({
            type: NOTIFICATION_TYPES.INFO,
            title: 'Template Set as Inactive',
            message: `The template has been saved as inactive because there is already an active template of type "${this.getEmailTypeLabel(template.emailTemplateType)}".`,
          });
        }
      } else if (template.isActive && this.editMode) {
        // If editing and activating, check for other active templates of same type
        const existingActiveTemplate = this.templatesSearch.find(
          t => t.emailTemplateType === template.emailTemplateType && 
             t.isActive === true && 
             t.id !== template.id && 
             t._id !== template.id
        );
        
        if (existingActiveTemplate) {
          // Force this template to be inactive - no confirmation dialog
          template.isActive = false;
          
          // Inform the user
          NotificationService.alert({
            type: NOTIFICATION_TYPES.INFO,
            title: 'Template Set as Inactive',
            message: `The template has been saved as inactive because there is already an active template of type "${this.getEmailTypeLabel(template.emailTemplateType)}".`,
          });
        }
      }
      
      this.loading = true;
      const action = this.editMode ? UPDATE_EMAIL_TEMPLATE : ADD_EMAIL_TEMPLATE;
      
      this.$store
        .dispatch(action, template)
        .then(() => {
          this.fetchTemplates();
          this.templateDialog = false;
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: `Email template ${this.editMode ? 'updated' : 'created'} successfully.`,
          });
        })
        .catch((error) => {
          this.loading = false;
          let errorMessage = `Error ${this.editMode ? 'updating' : 'creating'} email template`;
          
          if (error && error.message === 'Current company not found') {
            errorMessage = `Unable to ${this.editMode ? "update" : "create"} email template: Company information not available`;
          }
          
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error!',
            message: errorMessage,
          });
        });
    },
    confirmDeleteTemplate(template) {
      if (!this.canManageEmailTemplates) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Permission Denied',
          message: 'You do not have permission to delete email templates.',
        });
        return;
      }
      
      // Log the template object to debug
      console.log('Template to delete:', template);
      console.log('Template ID:', template.id);
      
      // Make a copy of the template to avoid reference issues
      this.templateToDelete = JSON.parse(JSON.stringify(template));
      
      // Ensure the ID is set correctly
      if (template._id && !this.templateToDelete.id) {
        this.templateToDelete.id = template._id;
      }
      
      this.deleteDialog = true;
    },
    deleteTemplate(templateId) {
      if (!this.canManageEmailTemplates) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Permission Denied',
          message: 'You do not have permission to delete email templates.',
        });
        return;
      }
      
      if (!templateId) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'No Template Selected',
          message: 'Please select a template to delete.',
        });
        return;
      }

      // Log the template ID for debugging
      console.log('Deleting template with ID:', templateId);

      this.loading = true;
      this.$store
        .dispatch(DELETE_EMAIL_TEMPLATE, templateId)
        .then(() => {
          this.fetchTemplates();
          this.deleteDialog = false;
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Email template deleted successfully.',
          });
        })
        .catch((error) => {
          this.loading = false;
          let errorMessage = 'Error deleting email template';
          if (error && error.message === 'Current company not found') {
            errorMessage = 'Unable to delete email template: Company information not available';
          }
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error!',
            message: errorMessage,
          });
        });
    },
    hasActiveTemplateOfSameType(template) {
      if (!template || !template.emailTemplateType) return false;
      
      return this.templatesSearch.some(t => 
        t.emailTemplateType === template.emailTemplateType && 
        t.isActive === true && 
        t.id !== template.id && 
        t._id !== template.id
      );
    },
    
    shouldDisableSwitch(template) {
      // Only disable inactive templates when another of same type is active
      return !template.isActive && this.hasActiveTemplateOfSameType(template);
    },
    
    showWarningIcon(template) {
      // Only show warning on inactive templates when another of same type is active
      return !template.isActive && this.hasActiveTemplateOfSameType(template);
    },
    toggleTemplateStatus(template) {
      if (!this.canManageEmailTemplates) {
        NotificationService.alert({
          type: NOTIFICATION_TYPES.WARNING,
          title: 'Permission Denied',
          message: 'You do not have permission to change template status.',
        });
        // Revert the toggle
        template.isActive = !template.isActive;
        return;
      }
      
      const newStatus = template.isActive;
      
      // If trying to activate, check if there's an existing active template
      if (newStatus === true && this.hasActiveTemplateOfSameType(template)) {
        // Revert the toggle to inactive
        template.isActive = false;
        
        // Inform the user
        NotificationService.alert({
          type: NOTIFICATION_TYPES.INFO,
          title: 'Cannot Activate',
          message: `There is already an active template of type "${this.getEmailTypeLabel(template.emailTemplateType)}". Only one template per type can be active.`,
        });
        return;
      }
      
      // Make a copy to avoid reference issues
      const updatedTemplate = JSON.parse(JSON.stringify(template));
      
      // Update the template status
      this.$store
        .dispatch(UPDATE_EMAIL_TEMPLATE, updatedTemplate)
        .then(() => {
          // Manually update all templates with the same type to maintain UI consistency
          if (newStatus === true) {
            // If activating this template, deactivate all others of same type
            this.templatesSearch.forEach(t => {
              if (t.emailTemplateType === template.emailTemplateType && 
                  (t.id !== template.id && t._id !== template.id)) {
                t.isActive = false;
              }
            });
          }
          
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Status Updated',
            message: `Template status changed to ${newStatus ? 'Active' : 'Inactive'}.`,
          });
        })
        .catch((error) => {
          // Revert the toggle if there's an error
          template.isActive = !newStatus;
          
          NotificationService.alert({
            type: NOTIFICATION_TYPES.ERROR,
            title: 'Error!',
            message: `Error updating template status: ${error.message || 'Unknown error'}`,
          });
        });
    },
    showNotification(notification) {
      NotificationService.alert({
        type: notification.type || NOTIFICATION_TYPES.INFO,
        title: notification.title || 'Notification',
        message: notification.message || '',
      });
    }
  },
  mounted() {
    // Fetch templates when component is mounted
    this.fetchTemplates();
    
    // Fetch email types for the dropdown
    this.fetchEmailTypes();
  },
  watch: {
    search() {
      this.fetchTemplates();
    }
  }
};
</script>

<style scoped>
/* Page header styles */
.page-header {
  margin-bottom: 24px;
  max-width: 100%;
  text-align: left;
}

.page-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: #1e3b70;
  margin-bottom: 12px;
  letter-spacing: 0.25px;
  position: relative;
  padding-bottom: 8px;
  text-align: left;
}

.page-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 48px;
  height: 3px;
  background-color: #1e3b70;
}

.page-description {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #546e7a;
  max-width: 100%;
  margin-top: 8px;
  text-align: left;
}

/* Add your styles here */
.email-templates-container {
  padding: 16px;
}

.action-bar {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 0;
  background-color: transparent;
}

.search-wrapper {
  max-width: 320px;
  margin-right: 16px;
  transition: all 0.3s ease;
}

.search-input {
  width: 100%;
}

.search-input >>> .v-input__control {
  min-height: 40px;
}

.search-input >>> .v-input__slot {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  border-bottom: 2px solid rgba(30, 59, 112, 0.2) !important;
  background-color: transparent !important;
  padding-left: 0 !important;
  margin-bottom: 4px;
}

.search-input >>> .v-input__prepend-inner {
  margin-top: 8px !important;
  margin-right: 8px !important;
  color: #1e3b70;
  font-size: 14px !important;
}

.search-input >>> .v-input__prepend-inner .v-icon {
  font-size: 16px !important;
  height: 16px !important;
  width: 16px !important;
}

.search-input >>> input {
  font-size: 14px !important;
  padding-top: 8px !important;
  color: #1e3b70 !important;
}

.search-input >>> .v-input__append-inner {
  margin-top: 8px !important;
}

.search-input:hover >>> .v-input__slot {
  border-bottom-color: rgba(30, 59, 112, 0.5) !important;
}

.search-input.v-input--is-focused >>> .v-input__slot {
  border-bottom-color: #1e3b70 !important;
}

.btn {
  display: inline-flex;
  align-items: center;
  background-color: #1e3b70;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  text-decoration: none;
  transition: background-color 0.2s;
}

.btn:hover {
  background-color: #152c54;
  cursor: pointer;
}

.fa-icon {
  margin-left: 8px;
}

.template-name {
  font-weight: 500;
  color: #1e3b70;
}

.template-type-chip {
  font-size: 12px;
  height: 24px;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
}

.no-data {
  padding: 24px;
  text-align: center;
  color: #546e7a;
  background-color: #f5f7f9;
  border-radius: 4px;
  margin-top: 16px;
}

.no-data a {
  color: #1e3b70;
  font-weight: 500;
  margin-left: 8px;
}

.status-container {
  display: flex;
  align-items: center;
}

.status-switch {
  margin-right: 8px !important;
}

.status-text {
  font-size: 0.875rem;
  font-weight: 500;
}

/* Responsive styles */
@media (max-width: 600px) {
  .action-bar {
    flex-direction: column;
    align-items: stretch;
  }
  
  .search-wrapper {
    max-width: 100%;
    margin-bottom: 16px;
  }
}
</style> 