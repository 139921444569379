<template>
  <v-container class="client-step-container">
      <v-row class="mb-8">
        <v-col class="instructions d-flex align-center">
          <h1>
            <span class="headerlight">
              {{ question }}
            </span>
          </h1>
          <v-tooltip v-if="description" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="ml-6"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>fas fa-question-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ description }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row justify="space-around mb-8">
        <v-date-picker
         :color="primaryColor" 
         v-model.trim="answer"  
         @change="updateAnswer" 
         required 
         hide-details="auto"
         :error-messages="answerErrors"
          @blur="$v.answer.$touch()"
         />
      </v-row>
      <v-row>
        <div v-if="answerErrors.length" class="error-message">
            <span v-for="error in answerErrors" :key="error" class="error-message">{{ error }}</span> <br />
        </div>
      </v-row>
    </v-container>
</template>

<script>

import { required } from 'vuelidate/lib/validators';

import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import Themable from '@/common/mixins/themable.mixin';
import { EventService } from '@/services/event.service';


export default {
  name: 'DatePicker',
  props: {
    question: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: true
    },
    description: {
      type: String,
      required: false
    }
  },
  mixins: [InterviewClientMixin, Themable],
  validations: {
    answer: { required },
  },
  data() {
    return {
      answer: null
    }
  },
  methods: {
    updateAnswer() {
      EventService.emit('update-answer', this.answer);
    },
    validate() {
      this.$v.$touch();
      if (!this.required && this.answer.length === 0) {
        return true;
      }
      return !this.$v.$invalid;
    },
  },
  computed: {
    answerErrors() {
      const errors = [];
      if (!this.$v.answer.$dirty) return errors;
      !this.$v.answer.required && errors.push('Answer is required');
      return errors;
    },
  }
}
</script>

<style lang="scss" scoped>
.error-message {
  color: var(--error-color);
  font-size: 14px;
}

.instructions {
  h1 {
    margin-bottom: 0;
  }
}
</style>