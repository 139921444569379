import {
  ACCOUNT_TYPES,
  DB_ACCOUNT_TYPES,
  MENUS,
  US_DATE_SHORT,
  formatDate,
  getClientPage,
  getClientPageIndex,
} from '@/common/constants';

import { mapGetters } from 'vuex';

const UnathenticatedClientMixin = {
  data: () => ({}),

  mixins: [],

  computed: {
    ...mapGetters([
      'clientProgress',
      'clientData',
      'isAuthenticated',
      'isClientAuthenticated',
    ]),

    /* Page Completions and Step Counts */
    isCitizenshipComplete() {
      return this.isPageComplete(MENUS.CLIENT.CITIZENSHIP.id);
    },
    isLegalNameComplete() {
      return this.isPageComplete(MENUS.CLIENT.LEGAL_NAME.id);
    },
    isLegalAddressComplete() {
      return this.isPageComplete(MENUS.CLIENT.LEGAL_ADDRESS.id);
    },
    legalCompletionSteps() {
      let c = 0;
      if (this.isCitizenshipComplete) c++;
      if (this.isLegalNameComplete) c++;
      if (this.isLegalAddressComplete) c++;
      return c;
    },
    isLegalComplete() {
      return this.legalCompletionSteps === 3;
    },
    isCustomQuestionComplete({ questionId }) {
      return this.isPageComplete(
        MENUS.CLIENT.CUSTOM_QUESTIONS.id,
        null,
        questionId,
      );
    },
    companyLogo() {
      if (this.clientProgress) return this.clientProgress.companyLogo;
      return null;
    },
    companyName() {
      if (this.clientProgress) return this.clientProgress.companyName;
      return '';
    },
    areCustomQuestionsComplete() {
      const customQuestionsPages =
        this.clientProgress &&
        this.clientProgress.progress.filter(
          (page) => page.pageName === MENUS.CLIENT.CUSTOM_QUESTIONS.id,
        );
      if (customQuestionsPages && customQuestionsPages.length > 0) {
        return customQuestionsPages.every((page) => {
          return page.filled === true && page.status === 'SYNCED';
        });
      }
      return true;
    },
    isSSNComplete() {
      return this.isPageComplete(MENUS.CLIENT.SSN.id);
    },
    isDOBComplete() {
      return this.isPageComplete(MENUS.CLIENT.DOB.id);
    },
    isAlternatePhoneComplete() {
      return this.isPageComplete(MENUS.CLIENT.ALTERNATE_PHONE.id);
    },
    basicCompletionSteps() {
      let c = 0;
      if (this.isSSNComplete) c++;
      if (this.isDOBComplete) c++;
      if (this.isAlternatePhoneComplete) c++;
      return c;
    },
    isBasicComplete() {
      return this.basicCompletionSteps === 3;
    },
    isEmploymentStatusComplete() {
      return this.isPageComplete(MENUS.CLIENT.EMPLOYMENT_STATUS.id);
    },
    isCompanyAddressComplete() {
      return this.isPageComplete(MENUS.CLIENT.COMPANY_ADDRESS.id);
    },
    isJobDescriptionComplete() {
      return this.isPageComplete(MENUS.CLIENT.JOB_DESCRIPTION.id);
    },
    isCompanyOfficialComplete() {
      return this.isPageComplete(MENUS.CLIENT.COMPANY_OFFICIAL.id);
    },
    isBeneficialInterestComplete() {
      return this.isPageComplete(MENUS.CLIENT.BENEFICIAL_INTEREST.id);
    },
    totalEmploymentSteps() {
      let c = 3;

      /* Only add company and job pages if present */
      const hasCompanyPage =
        this.getPage(MENUS.CLIENT.COMPANY_ADDRESS.id) !== undefined;
      if (hasCompanyPage) c++;

      const hasJobPage =
        this.getPage(MENUS.CLIENT.JOB_DESCRIPTION.id) !== undefined;
      if (hasJobPage) c++;

      return c;
    },
    employmentCompletionSteps() {
      let c = 0;
      if (this.isEmploymentStatusComplete) c++;

      /* Only count company and job pages if present */
      const hasCompanyPage =
        this.getPage(MENUS.CLIENT.COMPANY_ADDRESS.id) !== undefined;
      if (hasCompanyPage && this.isCompanyAddressComplete) c++;

      const hasJobPage =
        this.getPage(MENUS.CLIENT.JOB_DESCRIPTION.id) !== undefined;
      if (hasJobPage && this.isJobDescriptionComplete) c++;

      if (this.isCompanyOfficialComplete) c++;
      if (this.isBeneficialInterestComplete) c++;
      return c;
    },
    isEmploymentComplete() {
      return this.employmentCompletionSteps === this.totalEmploymentSteps;
    },
    beneficiaryCompletionSteps() {
      let c = 0;
      const accounts = this.clientData.accounts;

      /* Steps are based on the accounts designated */
      accounts.forEach((a) => {
        const hasPrimaryBeneficiary =
          this.getPage(
            MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
            a.instance,
          ) !== undefined;
        if (this.isAccountPrimaryBeneficiaryComplete(a.instance)) c++;
        if (
          hasPrimaryBeneficiary &&
          this.isAccountContingentBeneficiaryComplete(a.instance)
        )
          c++;
      });
      return c;
    },
    totalBeneficiarySteps() {
      let c = 0;
      const accounts = this.clientData.accounts;

      /* Only add step for Contingent beneficiaries for account if page present */
      accounts.forEach((a) => {
        c++;
        const hasPrimaryBeneficiary =
          this.getPage(
            MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
            a.instance,
          ) !== undefined;
        if (hasPrimaryBeneficiary) c++;
      });

      return c;
    },
    isBeneficiaryComplete() {
      return this.beneficiaryCompletionSteps === this.totalBeneficiarySteps;
    },
    canDesignateBeneficiaries() {
      return this.totalBeneficiarySteps > 0;
    },
    remainingCompletionSteps() {
      return 0;
    },
    isRemainingComplete() {
      return false;
    },
    incompleteSteps() {
      const steps = [];

      if (!this.isCitizenshipComplete)
        steps.push({
          name: 'Citizenship',
          route: { name: MENUS.CLIENT.CITIZENSHIP.id },
        });
      if (!this.isLegalNameComplete)
        steps.push({
          name: 'Legal Name',
          route: { name: MENUS.CLIENT.LEGAL_NAME.id },
        });
      if (!this.isLegalAddressComplete)
        steps.push({
          name: 'Legal Address',
          route: { name: MENUS.CLIENT.LEGAL_ADDRESS.id },
        });
      if (!this.isSSNComplete)
        steps.push({
          name: 'Social Security Number',
          route: { name: MENUS.CLIENT.SSN.id },
        });
      if (!this.isDOBComplete)
        steps.push({
          name: 'Date of Birth',
          route: { name: MENUS.CLIENT.DOB.id },
        });
      if (!this.isAlternatePhoneComplete)
        steps.push({
          name: 'Alternate Phone(s)',
          route: { name: MENUS.CLIENT.ALTERNATE_PHONE.id },
        });
      if (!this.isEmploymentStatusComplete)
        steps.push({
          name: 'Employment Status',
          route: { name: MENUS.CLIENT.EMPLOYMENT_STATUS.id },
        });

      /* Company Page only incomplete if present and not complete */
      const hasCompanyPage =
        this.getPage(MENUS.CLIENT.COMPANY_ADDRESS.id) !== undefined;
      if (hasCompanyPage && !this.isCompanyAddressComplete)
        steps.push({
          name: 'Business Name & Address',
          route: { name: MENUS.CLIENT.COMPANY_ADDRESS.id },
        });

      /* Job Page only incomplete if present and not complete */
      const hasJobPage =
        this.getPage(MENUS.CLIENT.JOB_DESCRIPTION.id) !== undefined;
      if (hasJobPage && !this.isJobDescriptionComplete)
        steps.push({
          name: 'Job Description',
          route: { name: MENUS.CLIENT.JOB_DESCRIPTION.id },
        });

      if (!this.isCompanyOfficialComplete)
        steps.push({
          name: 'Publicly Held Company',
          route: { name: MENUS.CLIENT.COMPANY_OFFICIAL.id },
        });
      if (!this.isBeneficialInterestComplete)
        steps.push({
          name: 'Associations',
          route: { name: MENUS.CLIENT.BENEFICIAL_INTEREST.id },
        });

      const accounts = this.clientData.accounts;
      const getAccountTypeName = (instance) => {
        if (instance.name === DB_ACCOUNT_TYPES.ROTH_IRA)
          return `${ACCOUNT_TYPES.ROTH_IRA} (${instance.label})`;
        else if (instance.name === DB_ACCOUNT_TYPES.IRA)
          return `${ACCOUNT_TYPES.IRA} (${instance.label})`;
        return '';
      };
      accounts.forEach((a) => {
        const primaryRouteInfo = {
          name: MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id,
          params: { name: a.instance.name, label: a.instance.label },
        };
        if (!this.isAccountPrimaryBeneficiaryComplete(a.instance))
          steps.push({
            name: `${getAccountTypeName(a.instance)} Primary Beneficiaries`,
            route: primaryRouteInfo,
          });

        /* Contingent Beneficiary Page only incomplete if present and not complete */
        const contingentRouteInfo = {
          name: MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
          params: { name: a.instance.name, label: a.instance.label },
        };
        const hasContingentBeneficiaryPage =
          this.getPage(
            MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
            a.instance,
          ) !== undefined;
        if (
          hasContingentBeneficiaryPage &&
          !this.isAccountContingentBeneficiaryComplete(a.instance)
        )
          steps.push({
            name: `${getAccountTypeName(a.instance)} Contingent Beneficiaries`,
            route: contingentRouteInfo,
          });
      });

      if (!this.areCustomQuestionsComplete) {
        const customQuestions = this.clientProgress
          ? this.clientProgress.progress.filter(
              (page) => page.pageName === MENUS.CLIENT.CUSTOM_QUESTIONS.id,
            )
          : [];
        customQuestions.forEach((page) => {
          const { _id, title } = page.data.question;
          if (!page.lastCompleted || page.status !== 'SYNCED') {
            steps.push({
              name: title,
              route: {
                name: MENUS.CLIENT.CUSTOM_QUESTIONS.id,
                params: { questionId: _id },
              },
            });
          }
        });
      }
      return steps;
    },
    requiredDocuments() {
      const requiredDocuments = this.clientProgress
        ? this.clientProgress.uploads.map(({ name, updatedAt, ...rest }) => ({
            id: name,
            name: name.replace(
              /(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/g,
              ' ',
            ),
            uploaded: updatedAt ? formatDate(updatedAt, US_DATE_SHORT) : null,
            ...rest,
          }))
        : [];

      return requiredDocuments;
    },
    isLegalRoute() {
      let r = false;
      switch (this.$route.name) {
        case MENUS.CLIENT.CITIZENSHIP.id:
        case MENUS.CLIENT.LEGAL_NAME.id:
        case MENUS.CLIENT.LEGAL_ADDRESS.id:
          r = true;
          break;
      }
      return r;
    },
    isBasicRoute() {
      let r = false;
      switch (this.$route.name) {
        case MENUS.CLIENT.SSN.id:
        case MENUS.CLIENT.DOB.id:
        case MENUS.CLIENT.ALTERNATE_PHONE.id:
          r = true;
          break;
      }
      return r;
    },
    isEmploymentRoute() {
      let r = false;
      switch (this.$route.name) {
        case MENUS.CLIENT.EMPLOYMENT_STATUS.id:
        case MENUS.CLIENT.COMPANY_ADDRESS.id:
        case MENUS.CLIENT.JOB_DESCRIPTION.id:
        case MENUS.CLIENT.COMPANY_OFFICIAL.id:
        case MENUS.CLIENT.BENEFICIAL_INTEREST.id:
          r = true;
          break;
      }
      return r;
    },
    firstBeneficiaryRoute() {
      // Always the first account primary beneficiary page
      if (this.clientData.accounts && this.clientData.accounts.length > 0) {
        const firstAccount = this.clientData.accounts[0];
        return {
          name: MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id,
          params: {
            name: firstAccount.instance.name,
            label: firstAccount.instance.label,
          },
        };
      }

      return null;
    },
    lastBeneficiaryRoute() {
      if (this.clientData.accounts && this.clientData.accounts.length > 0) {
        const lastAccount =
          this.clientData.accounts[this.clientData.accounts.length - 1];
        const hasContingentBeneficiaryPage =
          this.getPage(
            MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
            lastAccount.instance,
          ) !== undefined;

        // Is last account has contingent beneficiary page (if present)
        // Otherwise last account primary beneficiary page
        return hasContingentBeneficiaryPage
          ? {
              name: MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
              params: {
                name: lastAccount.instance.name,
                label: lastAccount.instance.label,
              },
            }
          : {
              name: MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id,
              params: {
                name: lastAccount.instance.name,
                label: lastAccount.instance.label,
              },
            };
      }
      return null;
    },
    isBeneficiaryRoute() {
      let r = false;
      switch (this.$route.name) {
        case MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id:
        case MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id:
          r = true;
          break;
      }
      return r;
    },
    isFinishRoute() {
      return this.$route.name === MENUS.CLIENT.FINISH.id;
    },
  },

  methods: {
    isFirstPage(pageName, instance = null, questionId = null) {
      return (
        getClientPageIndex(
          this.clientProgress,
          pageName,
          instance,
          questionId,
        ) === 0
      );
    },
    /*
      Get a page from the page list
    */
    getPage(pageName, instance = null, question = null) {
      return getClientPage(this.clientProgress, pageName, instance, question);
    },

    /* 
      Page is complete if:
      1. Page doesn't exist (not required for this interview)
      2. OR (page exists AND has been filled AND is synced)
    */
    isPageComplete(pageName, instance = null, question = null) {
      if (!this.clientProgress) return false;

      const progressPage = this.getPage(pageName, instance, question);
      
      // If page doesn't exist, it's not required for this interview flow
      if (!progressPage) return true;
      
      // Page exists - check if it's filled and synced
      return progressPage.lastCompleted !== null && 
             progressPage.status === 'SYNCED';
    },

    /*
      Is Primary Beneficiary Page complete for this account instance
    */
    isAccountPrimaryBeneficiaryComplete(instance) {
      return this.isPageComplete(
        MENUS.CLIENT.ACCOUNT_PRIMARY_BENEFICIARIES.id,
        instance,
      );
    },

    /*
      Is Contingent Beneficiary Page complete for this account instance
    */
    isAccountContingentBeneficiaryComplete(instance) {
      return this.isPageComplete(
        MENUS.CLIENT.ACCOUNT_CONTINGENT_BENEFICIARIES.id,
        instance,
      );
    },
  },

  created() {},
};
export default UnathenticatedClientMixin;
