<template>
  <v-dialog v-model="dialogVisible" max-width="1000px" persistent @input="updateVisibility">
    <v-card>
      <v-card-title class="py-2">
        <span class="headline">{{ isEditMode ? 'Edit' : 'Add' }} Email Template</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="showModeInfo"
              class="mr-2"
            >
              <v-icon>fas fa-question-circle</v-icon>
            </v-btn>
          </template>
          <span>Learn about editor modes</span>
        </v-tooltip>
        <v-btn icon small @click="cancel">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="dialog-content py-2">
        <v-container class="pa-0">
          <v-form ref="templateForm">
            <v-row>
              <!-- Left Column - Template Options -->
              <v-col cols="12" md="4" class="pr-md-4 template-options">
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      v-model="localTemplate.emailTemplateType"
                      :items="emailTypesForSelect"
                      item-text="text"
                      item-value="value"
                      label="Template Type*"
                      required
                      :rules="[v => !!v || 'Template type is required']"
                      @change="updateAvailablePlaceholders"
                      :loading="loadingEmailTypes"
                      :disabled="isEditMode"
                      outlined
                      dense
                    >
                      <template v-slot:item="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              {{ item.text }}
                            </div>
                          </template>
                          <span>{{ item.description }}</span>
                        </v-tooltip>
                      </template>
                    </v-select>

                    <div v-if="isEditMode || (localTemplate.emailTemplateType && hasActiveTemplateWarning)" class="d-flex align-center mt-2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="d-flex align-center">
                            <v-switch
                              v-model="localTemplate.isActive"
                              :color="localTemplate.isActive ? 'success' : 'grey'"
                              :label="localTemplate.isActive ? 'Active' : 'Inactive'"
                              class="mt-0"
                              dense
                              hide-details
                              :disabled="hasActiveTemplateWarning && !isEditMode"
                            ></v-switch>
                            <v-icon 
                              v-if="hasActiveTemplateWarning" 
                              x-small 
                              color="error" 
                              class="ml-2"
                            >
                              fas fa-exclamation-circle
                            </v-icon>
                          </div>
                        </template>
                        <span>
                          <strong>Active:</strong> Automatically used by the system when needed.<br>
                          <strong>Inactive:</strong> Stored but not used by the system.
                          <template v-if="hasActiveTemplateWarning">
                            <br><br><span class="error--text"><strong>Note:</strong> There is already an active template of this type. Only one template per type can be active.</span>
                          </template>
                        </span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  
                  <v-col cols="12">
                    <v-text-field
                      v-model="localTemplate.emailTemplateName"
                      label="Template Name*"
                      required
                      :rules="[v => !!v || 'Template name is required']"
                      :disabled="!localTemplate.emailTemplateType"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" v-if="selectedTypeDescription">
                    <div class="type-description text--secondary font-italic caption">
                      <v-icon small color="info" class="mr-1">fas fa-info-circle</v-icon>
                      {{ selectedTypeDescription }}
                    </div>
                  </v-col>
                  
                  <v-col cols="12" v-if="localTemplate.emailTemplateType && filteredPlaceholders.length > 0">
                    <div class="available-placeholders mb-1 py-2">
                      <div class="d-flex align-center mb-1">
                        <v-icon x-small color="primary" class="mr-1">fas fa-tags</v-icon>
                        <span class="caption font-weight-medium">Available Placeholders</span>
                      </div>
                      <div class="placeholder-chips">
                        <v-chip
                          v-for="placeholder in filteredPlaceholders"
                          :key="placeholder"
                          class="ma-1"
                          color="primary"
                          outlined
                          small
                          @click="(event) => insertPlaceholder(isOrganizationLogo(placeholder) ? 'organizationLogo' : placeholder, event)"
                        >
                          {{ placeholder }}
                        </v-chip>
                      </div>
                      <div class="caption grey--text mt-1">
                        Click on a placeholder to insert it at the cursor position
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              
              <!-- Right Column - Editor -->
              <v-col cols="12" md="8" class="pl-md-4 template-editor-container">
                <v-row dense>
                  <v-col cols="12">
                    <div class="template-buttons-container d-flex align-center mb-2">
                      <v-menu
                        offset-y
                        :close-on-content-click="false"
                        :nudge-width="200"
                        ref="templateMenu"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                              <v-btn 
                                small 
                                outlined 
                                color="primary" 
                                class="mr-2"
                                v-bind="{ ...attrs, ...tooltipAttrs }"
                                v-on="{ ...on, ...tooltipOn }"
                                :disabled="!localTemplate.emailTemplateType"
                              >
                                <v-icon small left>fas fa-file-import</v-icon>
                                Load Template
                                <v-icon small right>fas fa-caret-down</v-icon>
                              </v-btn>
                            </template>
                            <span>Load template in Simple or Advanced mode</span>
                          </v-tooltip>
                        </template>
                        
                        <v-card>
                          <v-list dense>
                            <v-list-item @click="loadDefaultTemplate" :disabled="!hasDefaultTemplate">
                              <v-list-item-icon>
                                <v-icon small color="primary">fas fa-edit</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Simple Mode</v-list-item-title>
                                <v-list-item-subtitle class="caption">
                                  Load template in WYSIWYG editor
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            
                            <v-divider></v-divider>
                            
                            <v-list-item @click="loadStyledHtmlTemplate" :disabled="!hasDefaultTemplate">
                              <v-list-item-icon>
                                <v-icon small color="amber darken-3">fas fa-code</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Advanced Mode</v-list-item-title>
                                <v-list-item-subtitle class="caption">
                                  Load template in HTML editor
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                          
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              x-small
                              text
                              color="grey darken-1"
                              @click="closeTemplateMenu"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                      
                      <v-btn 
                        small
                        outlined 
                        color="primary" 
                        @click="openPreview"
                        :disabled="!localTemplate.emailTemplateContent"
                        class="preview-button"
                      >
                        <v-icon small left>fas fa-eye</v-icon>
                        Preview
                      </v-btn>
                    </div>
                    <div class="template-content">
                      <template-editor
                        v-model="localTemplate.emailTemplateContent"
                        :editor-mode.sync="editorMode"
                        :disabled="localTemplate.emailTemplateType === 'DEFAULT'"
                        :show-validation-error="templateFormSubmitted && !localTemplate.emailTemplateContent"
                        ref="templateEditor"
                        @placeholder-inserted="handlePlaceholderInserted"
                      ></template-editor>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-2">
        <v-spacer></v-spacer>
        <v-btn text small @click="cancel">Cancel</v-btn>
        <v-btn 
          color="primary" 
          text
          small
          @click="save"
          :disabled="!localTemplate.emailTemplateType || !localTemplate.emailTemplateName || !localTemplate.emailTemplateContent"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    
    <!-- Preview Dialog -->
    <template-preview-dialog
      :visible.sync="previewDialog"
      :content="localTemplate.emailTemplateContent"
      :template-name="localTemplate.emailTemplateName"
      :template-type="getEmailTypeLabel(localTemplate.emailTemplateType)"
      :placeholders="availablePlaceholders"
    ></template-preview-dialog>

    <!-- Mode Info Dialog -->
    <v-dialog
      v-model="modeInfoDialogVisible"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="subtitle-1 primary--text">
          Email Template Editor Modes
          <v-spacer></v-spacer>
          <v-btn icon @click="modeInfoDialogVisible = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h3 class="subtitle-1 font-weight-medium">Simple Mode</h3>
              <v-list-item three-line>
                <v-list-item-avatar>
                  <v-icon large color="primary" class="mt-2">fas fa-edit</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <ul class="ml-2">
                      <li>User-friendly WYSIWYG (What You See Is What You Get) editor</li>
                      <li>Perfect for most users - no HTML knowledge required</li>
                      <li>Formatting toolbar for easy styling</li>
                      <li>Insert links and placeholders easily</li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            
            <v-divider></v-divider>
            
            <v-col cols="12">
              <h3 class="subtitle-1 font-weight-medium">Advanced Mode</h3>
              <v-list-item three-line>
                <v-list-item-avatar>
                  <v-icon large color="amber darken-3" class="mt-2">fas fa-code</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <ul class="ml-2">
                      <li>Direct HTML code editing</li>
                      <li>For users familiar with HTML code</li>
                      <li>Complete control over template structure and styling</li>
                      <li>Allow for use of images, css and more advanced features</li>
                      <li>Can load default styles as a starting point</li>
                      <li>Warning: Incorrect HTML syntax may cause display issues</li>
                    </ul>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          
          <v-alert
            text
            type="info"
            color="primary"
            class="mt-3"
            dense
          >
            <strong>Tip:</strong> You can always use the "Preview" button to see how your template will look before saving.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="modeInfoDialogVisible = false"
          >
            Got it
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import TemplateEditor from './TemplateEditor.vue';
import TemplatePreviewDialog from './TemplatePreviewDialog.vue';
import { mapGetters } from 'vuex';
import PublicAssets from '@/common/mixins/publicasset.mixin';
import { ASSET_KEYS } from '@/common/constants';

export default {
  name: 'TemplateDialog',
  components: {
    TemplateEditor,
    TemplatePreviewDialog
  },
  mixins: [PublicAssets],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    template: {
      type: Object,
      default: () => ({
        emailTemplateName: '',
        emailTemplateType: '',
        emailTemplateContent: '',
        isActive: true
      })
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    emailTypes: {
      type: Array,
      default: () => []
    },
    loadingEmailTypes: {
      type: Boolean,
      default: false
    },
    existingTemplates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: this.visible,
      localTemplate: { ...this.template },
      templateFormSubmitted: false,
      editorMode: 'wysiwyg',
      previewDialog: false,
      modeInfoDialogVisible: false,
      availablePlaceholders: [],
      previewVisible: false,
      hasActiveTemplateWarning: false,
      useOrganizationLogo: false
    };
  },
  computed: {
    ...mapGetters(['currentCompany']),
    emailTypesForSelect() {
      return this.emailTypes.map(type => ({
        text: type.label,
        value: type.emailType,
        description: type.description,
        defaultTemplate: type.defaultTemplate || ''
      }));
    },
    selectedTypeDescription() {
      if (!this.localTemplate.emailTemplateType) return '';
      const selectedType = this.emailTypes.find(
        type => type.emailType === this.localTemplate.emailTemplateType
      );
      return selectedType ? selectedType.description : '';
    },
    hasDefaultTemplate() {
      if (!this.localTemplate.emailTemplateType) return false;
      const selectedType = this.emailTypes.find(
        type => type.emailType === this.localTemplate.emailTemplateType
      );
      return selectedType && selectedType.defaultTemplate && selectedType.defaultTemplate.trim() !== '';
    },
    filteredPlaceholders() {
      // In simple mode (wysiwyg), only show organizationLogo from logo/image placeholders
      // In advanced mode (html), show all placeholders
      if (this.editorMode === 'wysiwyg') {
        return this.availablePlaceholders.filter(placeholder => {
          const cleanPlaceholder = placeholder.replace(/[{}]/g, '').toLowerCase();
          
          // Always include organizationLogo in any mode
          if (cleanPlaceholder === 'organizationlogo') {
            return true;
          }
          
          // In simple mode, exclude other logo/image placeholders
          if (cleanPlaceholder.includes('logo') || cleanPlaceholder.includes('image')) {
            return false;
          }
          
          // Include all other placeholders
          return true;
        });
      }
      
      // In advanced mode, show all placeholders
      return this.availablePlaceholders;
    },
    hasAdvancedPlaceholders() {
      // Check if there are any placeholders that contain 'logo' or 'image'
      return this.availablePlaceholders.some(placeholder => {
        const lowerPlaceholder = placeholder.toLowerCase();
        return lowerPlaceholder.includes('logo') || lowerPlaceholder.includes('image');
      });
    },
    hasOrganizationLogoPlaceholder() {
      return this.availablePlaceholders.some(placeholder => {
        const lowerPlaceholder = placeholder.toLowerCase();
        return lowerPlaceholder === 'organizationlogo';
      });
    },
    organizationLogoUrl() {
      if (!this.currentCompany || !this.currentCompany.assets) return '';
      const logo = this.currentCompany.assets.find(
        (asset) => asset.assetType === ASSET_KEYS.LOGO
      );
      return logo ? logo.assetLocation : '';
    }
  },
  watch: {
    visible(newVal) {
      this.dialogVisible = newVal;
    },
    template: {
      handler(newVal) {
        this.localTemplate = JSON.parse(JSON.stringify(newVal));
        this.updateAvailablePlaceholders();
        
        // Check if organisation logo is already in the content
        if (this.localTemplate.emailTemplateContent && 
            this.localTemplate.emailTemplateContent.includes('{{organizationLogo}}')) {
          this.useOrganizationLogo = true;
        } else {
          this.useOrganizationLogo = false;
        }
      },
      deep: true
    },
    editorMode() {
      // When editor mode changes, we don't need to update the base placeholders,
      // as filteredPlaceholders computed property will handle the filtering
      // This is just to trigger a re-render if needed
      this.$forceUpdate();
    }
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
    save() {
      this.templateFormSubmitted = true;
      
      if (!this.localTemplate.emailTemplateName || 
          !this.localTemplate.emailTemplateType || 
          !this.localTemplate.emailTemplateContent) {
        return;
      }
      
      // Skip all HTML processing on save - content should already be properly formatted
      // from the editor and placeholder insertion operations
      
      this.$emit('save', this.localTemplate);
    },
    updateVisibility(value) {
      this.$emit('update:visible', value);
    },
    updateAvailablePlaceholders() {
      const selectedType = this.emailTypes.find(
        (type) => type.emailType === this.localTemplate.emailTemplateType
      );
      
      // Get placeholders from the selected type
      let placeholders = selectedType ? selectedType.availablePlaceholders : [];
      
      // Ensure organizationLogo is always available by adding it if it doesn't exist
      const organizationLogoPlaceholder = 'organizationLogo';
      const hasOrgLogo = placeholders.some(placeholder => {
        // Clean the placeholder string for consistent comparison
        const clean = placeholder.replace(/[{}]/g, '').toLowerCase();
        return clean === organizationLogoPlaceholder.toLowerCase();
      });
      
      // Add the organizationLogo placeholder if it's not already in the list
      if (!hasOrgLogo) {
        placeholders.push(organizationLogoPlaceholder);
      }
      
      this.availablePlaceholders = placeholders;
      
      // Only clear the template content when not in edit mode
      // This prevents clearing content when editing an existing template
      if (!this.isEditMode) {
        this.localTemplate.emailTemplateContent = '';
        
        // Check if there's already an active template of this type
        this.checkForExistingActiveTemplate();
      }
    },
    checkForExistingActiveTemplate() {
      if (!this.isEditMode && this.localTemplate.emailTemplateType) {
        // Find if there's an active template with the same type
        const existingActiveTemplate = this.existingTemplates.find(
          template => template.emailTemplateType === this.localTemplate.emailTemplateType && 
                      template.isActive === true
        );
        
        if (existingActiveTemplate) {
          // Always set this template as inactive when there's an active template of the same type
          this.localTemplate.isActive = false;
          this.hasActiveTemplateWarning = true;
        } else {
          // No active template exists, set this one as active by default
          this.localTemplate.isActive = true;
          this.hasActiveTemplateWarning = false;
        }
      }
    },
    loadDefaultTemplate() {
      if (!this.localTemplate.emailTemplateType) return;
      
      const selectedType = this.emailTypes.find(
        (type) => type.emailType === this.localTemplate.emailTemplateType
      );
      
      if (selectedType && selectedType.defaultTemplate) {
        // Store original content for confirmation check
        const originalContent = this.localTemplate.emailTemplateContent;
        
        // If there's already content, confirm before overwriting
        if (originalContent && originalContent.trim() !== '') {
          if (!confirm('This will replace your current content with the default template. Continue?')) {
            return;
          }
        }
        
        // Update the template content
        this.localTemplate.emailTemplateContent = selectedType.defaultTemplate;
        
        // Explicitly set to Simple mode
        this.editorMode = 'wysiwyg';
        
        // Show success notification
        this.$emit('notification', {
          type: 'success',
          title: 'Default Template Loaded',
          message: 'The default template has been loaded in Simple mode. You can customize it with the formatting tools.'
        });
        
        // Give focus back to the editor after loading template
        this.$nextTick(() => {
          if (this.$refs.templateEditor) {
            this.$refs.templateEditor.focus();
          }
        });
      }
    },
    insertPlaceholder(placeholder, event) {
      // Prevent event propagation if event is provided
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      
      // Use the template editor component's method
      if (this.$refs.templateEditor) {
        this.$refs.templateEditor.insertPlaceholder(placeholder);
      }
    },
    openPreview() {
      this.previewDialog = true;
    },
    getEmailTypeLabel(emailType) {
      const type = this.emailTypes.find(t => t.emailType === emailType);
      return type ? type.label : emailType;
    },
    // Process HTML content to ensure all images have size constraints
    processHtmlImages(html) {
      // If no content, return empty string
      if (!html) return '';
      
      let processedHtml = html;
      
      // Define standard dimensions for organization logo
      const logoWidth = 200;
      const logoHeight = 70;
      
      // Check if HTML already contains the organization logo placeholder
      const hasLogoPlaceholder = processedHtml.includes('{{organizationLogo}}');
      
      // Check for img tags with data-placeholder attribute as well
      const hasLogoImg = processedHtml.includes('data-placeholder="{{organizationLogo}}"');
      
      // Only consider the content to have a logo if either condition is true
      const hasLogo = hasLogoPlaceholder || hasLogoImg;
      
      // Organization logo handling
      // Create a template that works well in both simple and advanced modes
      const logoHeader = `<div style="text-align: center; margin-bottom: 20px; padding: 10px;">
        <img src="${this.organizationLogoUrl || '{{organizationLogo}}'}" 
             data-placeholder="{{organizationLogo}}"
             alt="Organization Logo" 
             style="max-width: ${logoWidth}px; max-height: ${logoHeight}px; width: ${logoWidth}px; height: ${logoHeight}px; margin: 0 auto;"
             width="${logoWidth}" 
             height="${logoHeight}">
      </div>`;
      
      // Add or remove logo based on checkbox
      if (this.useOrganizationLogo && !hasLogo) {
        // Add logo at the beginning of the content only if there's no logo already
        processedHtml = logoHeader + processedHtml;
      } else if (!this.useOrganizationLogo && hasLogo) {
        // Remove the logo section if it exists - updated regex to match the data-placeholder attribute
        const logoRegex = /<div\s+style="text-align:\s*center;[^>]*>[\s\S]*?<img\s+[^>]*data-placeholder="{{organizationLogo}}"[^>]*>[\s\S]*?<\/div>/gi;
        processedHtml = processedHtml.replace(logoRegex, '');
        
        // If we still have the placeholder, remove it (it might be inserted directly without HTML)
        if (processedHtml.includes('{{organizationLogo}}')) {
          processedHtml = processedHtml.replace(/{{organizationLogo}}/g, '');
          
          // Clean up any empty img tags that might be left
          processedHtml = processedHtml.replace(/<img[^>]*src=""[^>]*>/g, '');
        }
      }
      
      // Replace any remaining {{organizationLogo}} placeholders with the actual URL
      if (this.organizationLogoUrl && processedHtml.includes('{{organizationLogo}}')) {
        // Flag to track if we've already replaced the logo
        let logoReplaced = false;
        
        // First try to find img tags with the organizationLogo placeholder in a data-placeholder attribute
        // and update their src attribute
        const imgWithDataPlaceholderRegex = /<img([^>]*)data-placeholder="{{organizationLogo}}"([^>]*)>/gi;
        processedHtml = processedHtml.replace(imgWithDataPlaceholderRegex, (match, before, after) => {
          // Set flag that we've replaced the logo
          logoReplaced = true;
          
          // Make sure width and height attributes are included
          if (!match.includes('width=')) {
            after += ` width="${logoWidth}"`;
          }
          if (!match.includes('height=')) {
            after += ` height="${logoHeight}"`;
          }
          // Make sure style attributes include explicit width and height
          if (!match.includes('width:') || !match.includes('height:')) {
            if (match.includes('style=')) {
              // Add width and height to existing style
              after = after.replace(/style="([^"]*)"/, (styleMatch, styleContent) => {
                return `style="${styleContent}; width: ${logoWidth}px; height: ${logoHeight}px;"`;
              });
            } else {
              // Add a new style attribute with width and height
              after += ` style="width: ${logoWidth}px; height: ${logoHeight}px;"`;
            }
          }
          return `<img${before}data-placeholder="{{organizationLogo}}" src="${this.organizationLogoUrl}"${after}>`;
        });
        
        // Then replace any remaining plain text placeholders with a properly formatted img
        // But only if we haven't already replaced an img tag with the data-placeholder
        if (!logoReplaced) {
          processedHtml = processedHtml.replace(/{{organizationLogo}}/g, 
            `<img src="${this.organizationLogoUrl}" 
                  data-placeholder="{{organizationLogo}}" 
                  alt="Organization Logo" 
                  style="max-width: ${logoWidth}px; max-height: ${logoHeight}px; width: ${logoWidth}px; height: ${logoHeight}px;" 
                  width="${logoWidth}" 
                  height="${logoHeight}">`
          );
        }
      }
      
      // Continue with other image processing
      // Add max-width and max-height attributes to all images to prevent them from breaking email layouts
      const imgRegex = /<img[^>]*>/gi;
      let match;
      while ((match = imgRegex.exec(processedHtml)) !== null) {
        const img = match[0];
        if (!img.includes('style=')) {
          // Add both style and explicit width/height attributes
          const newImg = img.replace('>', ' style="max-width: 200px; max-height: 70px; width: 200px; height: 70px;" width="200" height="70" >');
          processedHtml = processedHtml.replace(img, newImg);
        } else if (!img.includes('width=') || !img.includes('height=')) {
          // If there's style but no width/height attributes, add them
          const newImg = img.replace('>', ' width="200" height="70" >');
          processedHtml = processedHtml.replace(img, newImg);
        }
      }
      
      return processedHtml;
    },
    showModeInfo() {
      this.modeInfoDialogVisible = true;
    },
    loadStyledHtmlTemplate() {
      if (!this.localTemplate.emailTemplateType) return;
      
      const selectedType = this.emailTypes.find(
        (type) => type.emailType === this.localTemplate.emailTemplateType
      );
      
      if (selectedType && selectedType.defaultTemplate) {
        // Store original content for confirmation check
        const originalContent = this.localTemplate.emailTemplateContent;
        
        // If there's already content, confirm before overwriting
        if (originalContent && originalContent.trim() !== '') {
          if (!confirm('This will replace your current content with the default template in Advanced mode. Continue?')) {
            return;
          }
        }
        
        // Update the template content
        this.localTemplate.emailTemplateContent = selectedType.defaultTemplate;
        
        // Switch to HTML mode (Advanced)
        this.editorMode = 'html';
        
        // Show success notification
        this.$emit('notification', {
          type: 'success',
          title: 'HTML Template Loaded',
          message: 'The template has been loaded in Advanced mode. You can now edit the HTML directly.'
        });
        
        // Give focus back to the editor after loading template
        this.$nextTick(() => {
          if (this.$refs.templateEditor) {
            this.$refs.templateEditor.focus();
          }
        });
      } else {
        // Fallback if no template is available
        this.$emit('notification', {
          type: 'warning',
          title: 'No Template Available',
          message: 'There is no default template available for the selected type.'
        });
      }
    },
    closeTemplateMenu() {
      if (this.$refs.templateMenu) {
        this.$refs.templateMenu.isActive = false;
      }
    },
    handleOrganizationLogoChange(value) {
      // Store the checkbox value
      this.useOrganizationLogo = value;
      
      // If no template type is selected, show warning and reset checkbox
      if (!this.localTemplate.emailTemplateType) {
        this.$emit('notification', {
          type: 'warning',
          title: 'Template Type Required',
          message: 'Please select a template type before using the organization logo.'
        });
        this.useOrganizationLogo = false;
      } else {
        // Process content to add logo
        this.localTemplate.emailTemplateContent = this.processHtmlImages(this.localTemplate.emailTemplateContent);
        
        // If in simple mode, ensure the editor updates properly
        if (this.editorMode === 'wysiwyg') {
          // Force the editor to refresh to show the changes
          this.$nextTick(() => {
            if (this.$refs.templateEditor) {
              this.$refs.templateEditor.refreshEditor();
              
              // If adding the logo, make sure it's visible at the top
              if (value) {
                // Scroll to top to show the logo
                const editorElement = this.$refs.templateEditor.$el.querySelector('.ck-editor__editable');
                if (editorElement) {
                  editorElement.scrollTop = 0;
                }
              }
            }
          });
        }
      }
    },
    handlePlaceholderInserted(placeholderInfo) {
      // If the placeholder is organizationLogo, update the checkbox
      if (placeholderInfo && placeholderInfo.placeholder === 'Organization Logo') {
        this.useOrganizationLogo = true;
      }
    },
    isOrganizationLogo(placeholder) {
      const lowerPlaceholder = placeholder.toLowerCase();
      return lowerPlaceholder === 'organizationlogo';
    },
    // Method to prepare content for preview - replace placeholders with actual values
    preparePreviewContent() {
      let previewContent = this.localTemplate.emailTemplateContent || '';
      
      // Replace the organization logo placeholder with the actual logo URL
      if (this.useOrganizationLogo && this.organizationLogoUrl) {
        previewContent = previewContent.replace(/{{organizationLogo}}/g, this.organizationLogoUrl);
      }
      
      return previewContent;
    }
  }
};
</script>

<style scoped>
/* Dialog styles */
.dialog-content {
  max-height: 75vh;
  overflow-y: auto;
  padding: 0 16px;
}

.v-card-title {
  background-color: #f5f7f9;
  padding: 16px 24px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.headline {
  font-size: 1.25rem !important;
  font-weight: 600;
  color: #1e3b70;
}

/* Two-column layout styles */
.template-options {
  position: relative;
}

@media (min-width: 960px) {
  .template-options::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.template-editor-container {
  display: flex;
  flex-direction: column;
}

.template-content {
  height: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
}

.template-content > :deep(.template-editor) {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Placeholder styles */
.available-placeholders {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 12px;
  background-color: #f5f7f9;
}

.placeholder-chips {
  display: flex;
  flex-wrap: wrap;
  margin: 6px 0;
}

.placeholder-chips .v-chip {
  cursor: pointer;
  margin-right: 8px !important;
  margin-bottom: 8px !important;
  transition: transform 0.2s;
  font-size: 0.85rem !important;
  font-weight: 500 !important;
  height: 28px !important;
  padding: 0 12px !important;
}

.placeholder-chips .v-chip:hover {
  transform: translateY(-2px);
  background-color: rgba(30, 59, 112, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.placeholder-chips .v-chip::before {
  color: #1e3b70 !important;
  opacity: 0.2 !important;
}

.type-description {
  background-color: rgba(0, 0, 0, 0.03);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.875rem;
}

/* Preview Button Styles */
.preview-button {
  transition: all 0.2s ease;
}

.preview-button:hover {
  background-color: rgba(30, 59, 112, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
</style> 