import { mapGetters } from 'vuex';
import {
  MENUS,
  ACCOUNT_TYPES,
  DATA_TYPES,
  RATE_TYPES,
  formatPhoneLocal,
} from '@/common/constants';

const AdvisorClientMixin = {
  props: {
    second: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ACCOUNT_TYPES: ACCOUNT_TYPES,
    DATA_TYPES: DATA_TYPES,
    RATE_TYPES: RATE_TYPES,
  }),

  mixins: [],

  computed: {
    ...mapGetters(['currentClient']),

    /* Helpers */
    clientObj() {
      return this.second ? this.clientTwo : this.clientOne;
    },
    isSecondClient() {
      return this.second;
    },
    hasSecondClient() {
      return this.addedSecondClient;
    },
    addedSecondClient() {
      return this.currentClient.addSecond === 'Y';
    },
    clientName() {
      return `${this.clientObj.firstName} ${this.clientObj.lastName}`;
    },
    clientFirstName() {
      return this.clientObj.firstName;
    },
    clientOneFirstName() {
      return this.currentClient.client1.firstName;
    },
    clientOneName() {
      return this.isClientOneNameComplete
        ? `${this.currentClient.client1.firstName} ${this.currentClient.client1.lastName}`
        : '';
    },
    clientOneEmail() {
      return this.clientOne.email;
    },
    clientOnePhone() {
      return this.clientOne.phone;
    },
    clientOneFormattedPhone() {
      return formatPhoneLocal(this.clientOnePhone);
    },
    clientTwoName() {
      return this.isClientTwoNameComplete
        ? this.hasSecondClient
          ? `${this.currentClient.client2.firstName} ${this.currentClient.client2.lastName}`
          : ''
        : '';
    },
    clientTwoEmail() {
      return this.clientTwo.email;
    },
    clientTwoPhone() {
      return this.clientTwo.phone;
    },
    clientTwoFormattedPhone() {
      return formatPhoneLocal(this.clientTwoPhone);
    },
    clientOne() {
      let client = this.currentClient.client1;
      if (!client) client = this.currentClient.client1 = {};
      return client;
    },
    clientTwo() {
      let client = this.currentClient.client2;
      if (!client) client = this.currentClient.client2 = {};
      return client;
    },
    canAddAccounts() {
      return this.currentClient.addAccountsSelected;
    },
    canAddCustomTemplates() {
      return this.currentClient.addCustomQuestionsSelected;
    },
    advisoryRate() {
      return this.currentClient.rateType === null ? null : this.currentClient.rateType;
    },
    isClientSelectionRoute() {
      let r = false;
      switch (this.$route.name) {
        case MENUS.ADVISOR.CLIENT.CLIENT_SELECTION.id:
        case MENUS.ADVISOR.CLIENT.CLIENT_ADD_SECOND.id:
          r = true;
          break;
      }
      return r;
    },
    isClientOneRoute() {
      let r = false;
      switch (this.$route.name) {
        case MENUS.ADVISOR.CLIENT.CLIENT_ONE_NAME.id:
        case MENUS.ADVISOR.CLIENT.CLIENT_ONE_EMAIL.id:
        case MENUS.ADVISOR.CLIENT.CLIENT_ONE_PHONE.id:
          r = true;
          break;
      }
      return r;
    },
    isClientTwoRoute() {
      let r = false;
      switch (this.$route.name) {
        case MENUS.ADVISOR.CLIENT.CLIENT_TWO_NAME.id:
        case MENUS.ADVISOR.CLIENT.CLIENT_TWO_EMAIL.id:
        case MENUS.ADVISOR.CLIENT.CLIENT_TWO_PHONE.id:
          r = true;
          break;
      }
      return r;
    },
    isAccountRoute() {
      let r = false;
      switch (this.$route.name) {
        case MENUS.ADVISOR.CLIENT.ACCOUNT_SELECTION.id:
        case MENUS.ADVISOR.CLIENT.ACCOUNT_FEATURES.id:
          r = true;
          break;
      }
      return r;
    },

    /* Completion Steps and Status */
    clientSelectionCompletionSteps() {
      return 0;
    },
    isClientSelectionComplete() {
      return this.clientOne.type && this.isSecondClientSelectionComplete;
    },
    isSecondClientSelectionComplete() {
      const s = this.currentClient.addSecond;
      return s === 'N' || (s === 'Y' && this.clientTwo.type);
    },
    selectionCompletionSteps() {
      return 1;
    },
    isClientOneNameComplete() {
      return this.clientOne.firstName && this.clientOne.lastName;
    },
    isClientOneEmailComplete() {
      return this.clientOne.email;
    },
    isClientOnePhoneComplete() {
      return this.clientOne.phone;
    },
    clientOneCompletionSteps() {
      let c = 0;
      if (this.isClientOneNameComplete) c++;
      if (this.isClientOneEmailComplete) c++;
      if (this.isClientOnePhoneComplete) c++;
      return c;
    },
    isClientTwoNameComplete() {
      return this.clientTwo.firstName && this.clientTwo.lastName;
    },
    isClientTwoEmailComplete() {
      return this.clientTwo.email;
    },
    isClientTwoPhoneComplete() {
      return this.clientTwo.phone;
    },
    clientTwoCompletionSteps() {
      let c = 0;
      if (this.isClientTwoNameComplete) c++;
      if (this.isClientTwoEmailComplete) c++;
      if (this.isClientTwoPhoneComplete) c++;
      return c;
    },
    isAccountSelectionComplete() {
      return (
        this.hasAccountsSelected(this.clientOne) &&
        (!this.addedSecondClient ||
          (this.addedSecondClient && this.hasAccountsSelected(this.clientTwo)))
      );
    },
    isAccountFeaturesComplete() {
      return (
        this.isAccountSelectionComplete &&
        this.isAccountFeaturesCompleteForClient() &&
        (!this.addedSecondClient ||
          (this.addedSecondClient &&
            this.isAccountFeaturesCompleteForClient()))
      );
    },
    accountCompletionSteps() {
      let c = 0;
      if (this.isAccountSelectionComplete) c++;
      if (this.isAccountFeaturesComplete) c++;
      return c;
    },
    isAdvisoryRateComplete() {
      const accounts = this.getAccountRateSelections();
      
      // If no rate type is selected (undefined or falsy), it's still considered complete
      if (!this.currentClient.rateType) {
        return true;
      }
      
      let missingRate = false;
      accounts.forEach((a) => {
        const accountMissingRate = a.features.find((f) => f.rate === undefined);
        if (accountMissingRate) missingRate = true;
      });
      
      return (
        this.isAccountSelectionComplete &&
        !missingRate
      );
    },
    advisoryCompletionSteps() {
      return this.isAdvisoryRateComplete ? 1 : 0;
    },
    isFirmRoleSelectionComplete() {
      return (
        this.currentClient.firmRoles &&
        this.currentClient.firmRoles.primaryAdvisor &&
        this.currentClient.firmRoles.primaryCSA
      );
    },
    firmRolesCompletionSteps() {
      return this.isFirmRoleSelectionComplete ? 1 : 0;
    },
    reviewCompletionSteps() {
      return 0;
    },
    isDataSelectionRoute() {
      return this.$route.name === MENUS.ADVISOR.CLIENT.DATA_SELECTION.id;
    },
    isDataSelectionComplete() {
      return this.selectionCompletionSteps === 1;
    },
    isClientOneComplete() {
      return this.clientOneCompletionSteps === 3;
    },
    isClientTwoComplete() {
      return this.clientTwoCompletionSteps === 3;
    },
    isAccountComplete() {
      return this.accountCompletionSteps === 2;
    },
    isAdvisoryRoute() {
      return this.$route.name === MENUS.ADVISOR.CLIENT.ADVISORY_RATE.id;
    },
    isAdvisoryComplete() {
      return this.advisoryCompletionSteps === 1;
    },
    isFirmRolesRoute() {
      return this.$route.name === MENUS.ADVISOR.CLIENT.FIRM_ROLES.id;
    },
    isFirmRolesComplete() {
      return this.firmRolesCompletionSteps === 1;
    },
    isReviewRoute() {
      return this.$route.name === MENUS.ADVISOR.CLIENT.REVIEW_AND_FINISH.id;
    },
    isCustomQuestionsRoute() {
      return this.$route.name === MENUS.ADVISOR.CLIENT.CUSTOM_QUESTIONS.id;
    },
    isReviewComplete() {
      return this.reviewCompletionSteps === 1;
    },
    areAllStepsComplete() {
      return (
        this.isClientSelectionComplete &&
        this.isDataSelectionComplete &&
        this.isClientOneComplete &&
        //this.isSecondClientSelectionComplete &&
        (!this.addedSecondClient || this.isClientTwoComplete) &&
        (!this.canAddAccounts ||
          (this.canAddAccounts &&
            this.isAccountComplete &&
            this.isAdvisoryComplete)) &&
        this.isFirmRolesComplete
      );
    },
  },

  methods: {
    /* Has any account type been selected for client */
    hasAccountsSelected(client) {
      const accounts = client.accounts || [];
      return accounts.length > 0;
    },

    /* Does every selected account have a master account number */
    isAccountFeaturesCompleteForClient() {
      // Master account numbers are optional so this validation always passes
      return true;
    },

    /* Get Clients and Accounts Selected */
    getAccountSelections() {
      // Get Account Selections
      const selections = [];

      // Client One Accounts
      if (this.clientOne.accounts) {
        this.clientOne.accounts.forEach((a) => {
          const account = {
            type: a.type,
            label: a.label,
            accountId: a.accountId,
            clients: [],
          };
          if (a.type === ACCOUNT_TYPES.JOINT_NAME_BROKERAGE) {
            account.ownership = a.ownership;
          }
          account.clients.push(this.clientOneName);
          selections.push(account);
        });
      }

      // Client Two Accounts
      if (this.hasSecondClient && this.clientTwo.accounts) {
        this.clientTwo.accounts.forEach((a) => {
          let account = selections.find(
            (sa) => sa.type === a.type && sa.label === a.label,
          );
          if (!account) {
            account = {
              type: a.type,
              label: a.label,
              accountId: a.accountId,
              clients: [],
            };

            if (a.type === ACCOUNT_TYPES.JOINT_NAME_BROKERAGE) {
              account.ownership = a.ownership;
            }
            selections.push(account);
          }
          account.clients.push(this.clientTwoName);
        });
      }

      return selections;
    },

    /* Get clients and features selected by account */
    getAccountFeatureSelections() {
      let jointAccounts = [];
      const selections = [];
      const accountTypesOne = [];
      const accountTypesTwo = [];

      // Get Features for Client One Accounts
      if (this.clientOne.accounts) {
        this.clientOne.accounts.forEach((a) => {
          const accountFeatureData = {
            name: a.type,
            nickname: a.label,
            moveMoney: a.moveMoney,
            acat: a.acat,
            iraDistribution: false,
            masterAccountNumber: a.masterAccountNumber,
          };
          if (a.type === ACCOUNT_TYPES.IRA) {
            accountFeatureData.iraDistribution = a.iraDistribution;
          }

          // Only need to access the joint accounts from the first client
          // as the same account exists on both
          if (a.type === ACCOUNT_TYPES.JOINT_NAME_BROKERAGE) {
            jointAccounts.push(a);
          } else {
            accountTypesOne.push(accountFeatureData);
          }
        });
      }

      // Get Features for Client Two Accounts
      if (this.hasSecondClient && this.clientTwo.accounts) {
        this.clientTwo.accounts.forEach((a) => {
          const accountFeatureData = {
            name: a.type,
            nickname: a.label,
            moveMoney: a.moveMoney,
            acat: a.acat,
            iraDistribution: false,
            masterAccountNumber: a.masterAccountNumber,
          };
          if (a.type === ACCOUNT_TYPES.IRA) {
            accountFeatureData.iraDistribution = a.iraDistribution;
          }
          if (a.type !== ACCOUNT_TYPES.JOINT_NAME_BROKERAGE) {
            accountTypesTwo.push(accountFeatureData);
          }
        });
      }

      if (accountTypesOne.length > 0) {
        selections.push({
          name: this.clientOneName,
          features: accountTypesOne,
        });
      }
      if (accountTypesTwo.length > 0) {
        selections.push({
          name: this.clientTwoName,
          features: accountTypesTwo,
        });
      }

      // Get Features for Joint Accounts
      if (jointAccounts.length > 0) {
        const jointFeatures = [];
        jointAccounts.forEach((ja) => {
          jointFeatures.push({
            name: this.ACCOUNT_TYPES.JOINT_NAME_BROKERAGE,
            nickname: ja.label,
            moveMoney: ja.moveMoney,
            acat: ja.acat,
            iraDistribution: false,
            masterAccountNumber: ja.masterAccountNumber,
          });
        });

        selections.push({
          name: `${this.clientOneName} and ${this.clientTwoName}`,
          features: jointFeatures,
        });
      }

      return selections;
    },

    /* Get clients and rates selected by account */
    getAccountRateSelections() {
      let jointAccounts = [];
      const selections = [];
      const accountTypesOne = [];
      const accountTypesTwo = [];

      // Get Features for Client One Accounts
      if (this.clientOne.accounts) {
        this.clientOne.accounts.forEach((a) => {
          const accountRateData = {
            name: a.type,
            nickname: a.label,
            rate: a.rate,
          };

          // Only need to access the joint accounts from the first client
          // as the same account exists on both
          if (a.type === ACCOUNT_TYPES.JOINT_NAME_BROKERAGE) {
            jointAccounts.push(a);
          } else {
            accountTypesOne.push(accountRateData);
          }
        });
      }

      // Get Features for Client Two Accounts
      if (this.hasSecondClient && this.clientTwo.accounts) {
        this.clientTwo.accounts.forEach((a) => {
          const accountRateData = {
            name: a.type,
            nickname: a.label,
            rate: a.rate,
          };

          if (a.type !== ACCOUNT_TYPES.JOINT_NAME_BROKERAGE) {
            accountTypesTwo.push(accountRateData);
          }
        });
      }

      if (accountTypesOne.length > 0) {
        selections.push({
          name: this.clientOneName,
          features: accountTypesOne,
          rateType: this.currentClient.rateType,
        });
      }
      if (accountTypesTwo.length > 0) {
        selections.push({
          name: this.clientTwoName,
          features: accountTypesTwo,
          rateType: this.currentClient.rateType,
        });
      }

      // Get Features for Joint Accounts
      if (jointAccounts.length > 0) {
        const jointFeatures = [];
        jointAccounts.forEach((ja) => {
          jointFeatures.push({
            name: this.ACCOUNT_TYPES.JOINT_NAME_BROKERAGE,
            nickname: ja.label,
            rate: ja.rate,
          });
        });

        selections.push({
          name: `${this.clientOneName} and ${this.clientTwoName}`,
          features: jointFeatures,
        });
      }

      return selections;
    },
  },
};

export default AdvisorClientMixin;
