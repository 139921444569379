<template>
  <email-templates-table></email-templates-table>
</template>

<script>
import EmailTemplatesTable from '@/components/advisor/email-templates/EmailTemplatesTable.vue';

export default {
  name: 'EmailTemplates',

  components: {
    EmailTemplatesTable,
  },

  data: () => ({}),

  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped></style> 