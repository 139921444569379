<template>
  <div>
    <component
      :is="getComponent(control)"
      :ref="getRef(control)"
      :question="question"
      :required="required"
      :options="options"
      :description="description"
      @validation-update="handleValidation"
    />
  </div>
</template>
<script>
import DatePicker from './DatePicker';
import FillingBlank from './FillingBlank';
import RadioGroup from './RadioGroup';
import CheckboxGroup from './CheckboxGroup';
import InputText from './Input';

export default {
  props: {
    control: {
      type: String,
      required: true
    },
    question: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: false
    },
    required: {
      type: Boolean,
      required: true
    },
    description: {
      type: String,
      required: false
    }
  },
  components: {
    InputText,
    DatePicker,
    FillingBlank,
    RadioGroup,
    CheckboxGroup
  },
  methods: {
    getComponent(control) {
      return {
        'multipleChoice': 'checkbox-group',
        'singleChoice': 'radio-group',
        'default': 'input-text',
        'text': 'input-text',
        'date': 'date-picker',
        'fillInBlank': 'filling-blank'
      }[control] || 'input-text';
    },
    getRef(control) {
      return {
        'multipleChoice': 'checkboxGroup',
        'singleChoice': 'radioGroup',
        'default': 'inputText',
        'text': 'inputText',
        'date': 'datePicker',
        'fillInBlank': 'fillingBlank'
      }[control] || null;
    },
    validate() {
      const currentRef = this.getRef(this.control);
      const childComponent = this.$refs[currentRef];

      if (!childComponent || typeof childComponent.validate !== 'function') {
        if (['multipleChoice', 'singleChoice', 'date'].includes(this.control)) {
          return false;
        }
        return true;
      }

      return childComponent.validate();
    }
  }
};
</script>