var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-templates-container"},[_vm._m(0),_c('div',{staticClass:"action-bar"},[_c('div',{staticClass:"search-wrapper"},[_c('v-text-field',{staticClass:"search-input",attrs:{"placeholder":"Search templates...","prepend-inner-icon":"fas fa-search fa-sm","clearable":"","hide-details":"","single-line":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-spacer'),(_vm.canManageEmailTemplates)?_c('a',{staticClass:"btn",attrs:{"color":"primary","dark":""},on:{"click":_vm.openAddTemplateDialog}},[_vm._v(" Add Template "),_vm._m(1)]):_vm._e()],1),_c('v-data-table',{staticClass:"responsive-table email-template-table",attrs:{"headers":_vm.templateHeaders,"items":_vm.templatesSearch,"items-per-page":_vm.limit,"page":_vm.page,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [5, 10, 15, 25, 50],
      'show-first-last-page': true
    }},on:{"update:page":function($event){_vm.page=$event},"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"template-name"},[_vm._v(_vm._s(item.emailTemplateName))])]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"template-type-chip",attrs:{"small":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.getEmailTypeLabel(item.emailTemplateType))+" ")])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"status-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('v-switch',{staticClass:"status-switch ma-0 pa-0",attrs:{"color":item.isActive ? 'success' : 'grey',"dense":"","hide-details":"","disabled":!_vm.canManageEmailTemplates || _vm.loading || _vm.shouldDisableSwitch(item)},on:{"change":function($event){return _vm.toggleTemplateStatus(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}}),_c('span',{class:['status-text', item.isActive ? 'success--text' : 'grey--text']},[_vm._v(" "+_vm._s(item.isActive ? 'Active' : 'Inactive')+" ")]),(_vm.showWarningIcon(item))?_c('v-icon',{staticClass:"ml-2",attrs:{"x-small":"","color":"error"}},[_vm._v(" fas fa-exclamation-circle ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_c('strong',[_vm._v("Active:")]),_vm._v(" Automatically used by the system when needed"),_c('br'),_c('strong',[_vm._v("Inactive:")]),_vm._v(" Stored but not used by the system "),(_vm.showWarningIcon(item))?[_c('br'),_c('br'),_c('span',{staticClass:"error--text"},[_c('strong',[_vm._v("Note:")]),_vm._v(" There is already an active template of this type. Only one template per type can be active.")])]:_vm._e()],2)])],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"actions-container"},[(_vm.canManageEmailTemplates)?_c('a',{staticClass:"p-0",on:{"click":function($event){return _vm.editTemplate(item)}}},[_c('v-icon',{staticClass:"mr-8",attrs:{"small":""}},[_vm._v(" fas fa-pencil ")])],1):_vm._e(),(_vm.canManageEmailTemplates)?_c('a',{staticClass:"p-0",on:{"click":function($event){return _vm.confirmDeleteTemplate(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-trash ")])],1):_vm._e()])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"no-data"},[_vm._v(" No email templates found. "),(_vm.canManageEmailTemplates)?_c('a',{on:{"click":_vm.openAddTemplateDialog}},[_vm._v("Add a new template.")]):_vm._e()])]},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})]},proxy:true}])}),_c('template-dialog',{attrs:{"visible":_vm.templateDialog,"template":_vm.editedTemplate,"is-edit-mode":_vm.editMode,"email-types":_vm.emailTypes,"loading-email-types":_vm.loadingEmailTypes,"existing-templates":_vm.templatesSearch},on:{"update:visible":function($event){_vm.templateDialog=$event},"save":_vm.saveTemplate,"notification":_vm.showNotification}}),_c('delete-confirmation-dialog',{attrs:{"visible":_vm.deleteDialog,"template-name":_vm.templateToDelete ? _vm.templateToDelete.emailTemplateName : '',"template-id":_vm.templateToDelete ? _vm.templateToDelete.id : ''},on:{"update:visible":function($event){_vm.deleteDialog=$event},"delete":_vm.deleteTemplate}}),_c('template-preview-dialog',{attrs:{"visible":_vm.previewDialog,"content":_vm.editedTemplate.emailTemplateContent,"template-name":_vm.editedTemplate.emailTemplateName,"template-type":_vm.getEmailTypeLabel(_vm.editedTemplate.emailTemplateType),"placeholders":_vm.availablePlaceholders},on:{"update:visible":function($event){_vm.previewDialog=$event}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header"},[_c('h1',{staticClass:"page-title"},[_vm._v("Email Templates")]),_c('p',{staticClass:"page-description"},[_vm._v(" Manage your organization's email templates. Create, edit, and control template availability. "),_c('strong',[_vm._v("Active templates")]),_vm._v(" are automatically used by the system when needed for email communications. "),_c('strong',[_vm._v("Inactive templates")]),_vm._v(" are stored in the system but not used. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"fa-icon"},[_c('i',{staticClass:"fas fa-plus-circle"})])}]

export { render, staticRenderFns }