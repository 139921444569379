import Dashboard from '@/views/advisor/dashboards/Dashboard';
import CompanyList from '@/views/advisor/dashboards/CompanyList';
import DocumentList from '@/views/advisor/dashboards/DocumentList';
import UserList from '@/views/advisor/dashboards/UserList';
import AddUsers from '@/views/advisor/dashboards/AddUsers';
import ClientList from '@/views/advisor/dashboards/ClientList';
import UserProfile from '@/views/advisor/profiles/UserProfile';
import CompanyCustomQuestions from '@/views/advisor/dashboards/CustomQuestions';
import CompanyProfile from '@/views/advisor/profiles/CompanyProfile';
import AdvisorClientMain from '@/views/advisor/client/AdvisorClientMain';
import ClientSelection from '@/views/advisor/client/ClientSelection';
import DataSelection from '@/views/advisor/client/DataSelection';
import ClientName from '@/views/advisor/client/ClientName';
import ClientEmail from '@/views/advisor/client/ClientEmail';
import ClientPhone from '@/views/advisor/client/ClientPhone';
import AccountSelection from '@/views/advisor/client/AccountSelection';
import AccountFeatures from '@/views/advisor/client/AccountFeatures';
import AdvisoryRate from '@/views/advisor/client/AdvisoryRate';
import CustomQuestions from '@/views/advisor/client/CustomQuestions';
import FirmRoles from '@/views/advisor/client/FirmRoles';
import ReviewAndFinish from '@/views/advisor/client/ReviewAndFinish';
import AddClientBatch from '@/views/advisor/client/AddClientBatch';
import Monitor from '@/views/advisor/dashboards/Monitor';
import TransitionSelection from '@/views/advisor/transitions/TransitionSelection';
import TransitionBatch from '@/views/advisor/transitions/TransitionBatch';
import SuccessClientTransition from '@/views/advisor/transitions/SuccessClientTransition';
import TransitionType from '@/views/advisor/transitions/TransitionType';
import TransitionHouseholds from '@/views/advisor/transitions/TransitionHouseholds';
import TransitionAccountReview from '@/views/advisor/transitions/manualEntry/TransitionClientReviewAndFinish';
import TransitionFirmRoles from '@/views/advisor/transitions/manualEntry/TransitionFirmRoles';
import TransitionClientPhone from '@/views/advisor/transitions/manualEntry/TransitionClientPhone';
import TransitionManualClientName from '@/views/advisor/transitions/manualEntry/TransitionManualClientName';
import TransitionClientSelection from '@/views/advisor/transitions/manualEntry/TransitionClientSelection';
import TransitionClientMain from '@/views/advisor/transitions/manualEntry/TransitionClientMain';
import TransitionManualClientEmail from '@/views/advisor/transitions/manualEntry/TransitionManualClientEmail';
import TransitionHouseholdValue from '@/views/advisor/transitions/manualEntry/TransitionHouseholdValue';
import EditTransition from '@/views/advisor/transitions/EditTransition';
import MessageTemplates from '@/views/advisor/dashboards/MessageTemplates';
import EmailTemplates from '@/views/advisor/dashboards/EmailTemplates';
import Error from '@/views/Error';
import AddTemplate from '@/views/advisor/dashboards/AddEditCustomQuestionTemplate';

import { MENUS } from '@/common/constants';
import QueueLogs from '@/views/advisor/dashboards/QueueLogs.vue';

const routes = [
  {
    path: '/error',
    name: MENUS.ERROR.id,
    component: Error,
    meta: { breadcrumb: '' },
  },

  {
    path: '/dashboard',
    name: MENUS.HOME.id,
    component: Dashboard,
    meta: { breadcrumb: '' },
    children: [
      {
        path: '/dashboard/monitor',
        name: MENUS.MONITOR.id,
        component: Monitor,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/companies',
        name: MENUS.COMPANIES.id,
        component: CompanyList,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/documents',
        name: MENUS.DOCUMENTS.id,
        component: DocumentList,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/company-profile/:id?',
        name: MENUS.COMPANY_PROFILE.id,
        component: CompanyProfile,
        props: true,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/custom-questions',
        name: MENUS.CUSTOM_QUESTIONS.id,
        component: CompanyCustomQuestions,
        props: true,
        meta: { breadcrumb: '' },
      },
      {
        path: 'dashboard/message-templates',
        name: MENUS.MESSAGE_TEMPLATES.id,
        component: MessageTemplates,
        meta: { breadcrumb: '' },
      },
      {
        path: 'dashboard/email-templates',
        name: MENUS.EMAIL_TEMPLATES.id,
        component: EmailTemplates,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/users',
        name: MENUS.USERS.id,
        component: UserList,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/users/add',
        name: MENUS.ADD_USERS.id,
        component: AddUsers,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/clients',
        name: MENUS.CLIENTS.id,
        component: ClientList,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/custom-questions/templates/:id?',
        name: MENUS.CUSTOM_QUESTIONS_TEMPLATES.id,
        component: AddTemplate,
        props: true,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/user-profile/:id?', // id is now optional and defaults to current user
        name: MENUS.USER_PROFILE.id,
        component: UserProfile,
        props: true,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/add-client-batch',
        name: MENUS.ADD_CLIENT_BATCH.id,
        component: AddClientBatch,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/clients/transitions/transition-type',
        name: MENUS.TRANSITIONS.TRANSITION_TYPE.id,
        component: TransitionType,
        meta: { breadcrumb: '' },
      },
      {
        path: '/dashboard/clients/transitions',
        name: MENUS.TRANSITIONS.TRANSITION_CLIENTS.id,
        component: TransitionSelection,
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/dashboard/clients/transitions/transition-batch',
        name: MENUS.TRANSITIONS.TRANSITION_BATCH.id,
        component: TransitionBatch,
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/dashboard/clients/transitions/success-transition',
        name: MENUS.TRANSITIONS.SUCCESS_TRANSITION.id,
        component: SuccessClientTransition,
        props: true,
        meta: {
          breadcrumb: '',
        },
      },

      {
        path: '/dashboard/clients/transitions/edit-transition',
        name: MENUS.TRANSITIONS.EDIT_TRANSITION.id,
        component: EditTransition,
        props: true,
        meta: {
          breadcrumb: '',
        },
      },

      {
        path: '/dashboard/logs',
        name: MENUS.QUEUE_LOGS.id,
        component: QueueLogs,
        props: true,
        meta: {
          breadcrumb: '',
        },
      },
    ],
  },

  {
    path: '/advisor/client/transitions/form',
    name: MENUS.TRANSITIONS.TRANSITION_FORM.id,
    component: TransitionClientMain,
    meta: { breadcrumb: '' },
    children: [
      {
        path: '/advisor/client/transitions/transition-household-table',
        name: MENUS.TRANSITIONS.TRANSITION_HOUSEHOLD.id,
        component: TransitionHouseholds,
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/advisor/client/transitions/form/client-selection',
        name: MENUS.TRANSITIONS.TRANSITION_FORM_SELECTION.id,
        component: TransitionClientSelection,
        props: { second: false },
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/advisor/client/transitions/enter-manually/1/client-email',
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_ONE_EMAIL.id,
        component: TransitionManualClientEmail,
        props: { second: false },
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/advisor/client/transitions/enter-manually/2/client-email',
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_TWO_EMAIL.id,
        component: TransitionManualClientEmail,
        props: { second: true },
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/transitions/enter-manually/firm-roles',
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.FIRM_ROLES.id,
        component: TransitionFirmRoles,
        props: { second: false },
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/advisor/client/transitions/enter-manually/review-and-finish',
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.REVIEW.id,
        component: TransitionAccountReview,
        props: true,
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/advisor/client/transitions/enter-manually/household-value',
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.HOUSEHOLD_VALUE.id,
        component: TransitionHouseholdValue,
        props: { second: false },
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/advisor/client/transitions/enter-manually/1/mobile',
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_ONE_PHONE.id,
        component: TransitionClientPhone,
        props: { second: false },
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/advisor/client/transitions/enter-manually/2/mobile',
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_TWO_PHONE.id,
        component: TransitionClientPhone,
        props: { second: true },
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/advisor/client/transitions/enter-manually/1/client-name',
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_ONE_NAME.id,
        component: TransitionManualClientName,
        props: { second: false },
        meta: {
          breadcrumb: '',
        },
      },
      {
        path: '/advisor/client/transitions/enter-manually/2/client-name',
        name: MENUS.TRANSITIONS.ENTER_MANUALLY.CLIENT_TWO_NAME.id,
        component: TransitionManualClientName,
        props: { second: true },
        meta: {
          breadcrumb: '',
        },
      },
    ],
  },

  {
    path: '/advisor/client/new',
    name: MENUS.ADVISOR.ADD_CLIENT.id,
    component: AdvisorClientMain,
    meta: { breadcrumb: '' },
    children: [
      {
        path: '/advisor/client/new/client-selection',
        name: MENUS.ADVISOR.CLIENT.CLIENT_SELECTION.id,
        component: ClientSelection,
        props: { second: false },
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/1/data-selection',
        name: MENUS.ADVISOR.CLIENT.DATA_SELECTION.id,
        component: DataSelection,
        props: { second: false },
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/1/name',
        name: MENUS.ADVISOR.CLIENT.CLIENT_ONE_NAME.id,
        component: ClientName,
        props: { second: false },
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/1/email',
        name: MENUS.ADVISOR.CLIENT.CLIENT_ONE_EMAIL.id,
        component: ClientEmail,
        props: { second: false },
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/1/mobile',
        name: MENUS.ADVISOR.CLIENT.CLIENT_ONE_PHONE.id,
        component: ClientPhone,
        props: { second: false },
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/2/name',
        name: MENUS.ADVISOR.CLIENT.CLIENT_TWO_NAME.id,
        component: ClientName,
        props: { second: true },
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/2/email',
        name: MENUS.ADVISOR.CLIENT.CLIENT_TWO_EMAIL.id,
        component: ClientEmail,
        props: { second: true },
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/2/mobile',
        name: MENUS.ADVISOR.CLIENT.CLIENT_TWO_PHONE.id,
        component: ClientPhone,
        props: { second: true },
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/account-selection',
        name: MENUS.ADVISOR.CLIENT.ACCOUNT_SELECTION.id,
        component: AccountSelection,
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/account-features',
        name: MENUS.ADVISOR.CLIENT.ACCOUNT_FEATURES.id,
        component: AccountFeatures,
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/advisory-rate',
        name: MENUS.ADVISOR.CLIENT.ADVISORY_RATE.id,
        component: AdvisoryRate,
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/firm-roles',
        name: MENUS.ADVISOR.CLIENT.FIRM_ROLES.id,
        component: FirmRoles,
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/custom-questions',
        name: MENUS.ADVISOR.CLIENT.CUSTOM_QUESTIONS.id,
        component: CustomQuestions,
        meta: { breadcrumb: '' },
      },
      {
        path: '/advisor/client/new/review-finish',
        name: MENUS.ADVISOR.CLIENT.REVIEW_AND_FINISH.id,
        component: ReviewAndFinish,
        meta: { breadcrumb: '' },
      },
    ],
  },
];

export default routes;
