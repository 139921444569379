/* VUEX Dispatchable Actions */

// AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const VERIFY_CODE = 'VERIFY_CODE';
export const PING = 'PING';
export const SESSION_TIMEOUT = 'timeout';
export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';

// USERS
export const CHANGE_LOGIN_EMAIL = 'CHANGE_LOGIN_EMAIL';
export const CONFIRM_LOGIN_EMAIL_CHANGE = '/aaa/confirmLoginEmail'; //  TODO:  delete this?

// PRODUCT
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const GET_DOCUSIGN_DOCUMENTS = 'GET_DOCUSIGN_DOCUMENTS';
export const UPSERT_DOCUSIGN_DOCUMENT = 'UPSERT_DOCUSIGN_DOCUMENT';
export const GET_AVAILABLE_CRMS = 'GET_AVAILABLE_CRMS';
export const TOGGLE_DOCUSIGN = 'TOGGLE_DOCUSIGN';
export const TOGGLE_TRANSITIONS = 'TOGGLE_TRANSITIONS';
export const TOGGLE_TRANSITION_NOTIFICATIONS =
  'TOGGLE_TRANSITION_NOTIFICATIONS';
export const TOGGLE_SMS_NOTIFICATIONS = 'TOGGLE_SMS_NOTIFICATIONS';
// CUSTOM QUESTIONS
export const GET_CUSTOM_QUESTIONS_TEMPLATES = 'GET_CUSTOM_QUESTIONS_TEMPLATES';
export const GET_CUSTOM_QUESTIONS_TEMPLATE = 'GET_CUSTOM_QUESTIONS_TEMPLATE';
export const ADD_CUSTOM_QUESTION_TEMPLATE = 'ADD_CUSTOM_QUESTION_TEMPLATE';
export const DELETE_CUSTOM_QUESTION_TEMPLATE =
  'DELETE_CUSTOM_QUESTION_TEMPLATE';
export const UPDATE_CUSTOM_QUESTION_TEMPLATE =
  'UPDATE_CUSTOM_QUESTION_TEMPLATE';

// EMAIL TEMPLATES
export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const GET_EMAIL_TEMPLATE = 'GET_EMAIL_TEMPLATE';
export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE';
export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE';
export const GET_EMAIL_TYPES = 'GET_EMAIL_TYPES';

// ORGANIZATION
export const GET_COMPANIES = 'GET_COMPANIES';
export const ADD_COMPANY = 'ADD_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const GET_CURRENT_COMPANY = 'GET_CURRENT_COMPANY';
export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';
export const UPDATE_COMPANY_CRM = 'UPDATE_COMPANY_CRM';
export const ASSET = '/organization/assets';
export const COMPANY_ASSETS = 'COMPANY_ASSETS';
export const GET_ASSET = 'get-asset';
export const UPLOAD_COMPANY_ASSET = 'UPLOAD_COMPANY_ASSET';
export const DOWNLOAD_COMPANY_ASSET = 'DOWNLOAD_COMPANY_ASSET';
export const UPLOAD_LOGO = 'UPLOAD_LOGO';
export const DELETE_ASSET = 'delete-asset';
export const UPDATE_COMPANY_ADDRESS = 'UPDATE_COMPANY_ADDRESS';
export const MASTER_ACCOUNT_NUMBER = 'MASTER_ACCOUNT_NUMBER';
export const ADD_MASTER_ACCOUNT_NUMBER = 'ADD_MASTER_ACCOUNT_NUMBER';
export const DELETE_MASTER_ACCOUNT_NUMBER = 'DELETE_MASTER_ACCOUNT_NUMBER';
export const DOCUSIGN_BASE = '/organization/integration/docusign';
export const GET_DOCUSIGN_CONSENT_URL = 'GET_DOCUSIGN_CONSENT_URL';
export const CHOOSE_DOCUSIGN_ACCOUNT = 'CHOOSE_DOCUSIGN_ACCOUNT';
export const CHOOSE_DOCUSIGN_ACCOUNT_OWNERSHIP =
  'CHOOSE_DOCUSIGN_ACCOUNT_OWNERSHIP';
export const UNLINK_DOCUSIGN = 'UNLINK_DOCUSIGN';

// ADVISOR
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const EDIT_USER = 'EDIT_USER';
export const UPDATE_MOBILE_PHONE = 'UPDATE_MOBILE_PHONE';
export const GET_SELF = 'GET_SELF';
export const EDIT_SELF = 'EDIT_SELF';
export const CHECK_REDTAIL_USER_STATUS = 'check-redtail-user-status';
export const LINK_REDTAIL = 'LINK_REDTAIL';
export const LINK_WEALTHBOX = 'LINK_WEALTHBOX';
export const LINK_SALESFORCE = 'LINK_SALESFORCE';
export const LINK_PRACTIFI = 'LINK_PRACTIFI';
export const UNLINK_CRM = 'UNLINK_CRM';
export const DOCUSIGN_USER_BASE =
  '/organization/currentuser/integration/docusign';
export const POST_DOCUSIGN_USER_CONSENT = 'POST_DOCUSIGN_USER_CONSENT';
export const CHOOSE_DOCUSIGN_USER_ACCOUNT = 'CHOOSE_DOCUSIGN_USER_ACCOUNT';
export const CHOOSE_DOCUSIGN_USER_ACCOUNT_OWNERSHIP =
  'CHOOSE_DOCUSIGN_USER_ACCOUNT_OWNERSHIP';
export const UNLINK_DOCUSIGN_USER = 'UNLINK_DOCUSIGN_USER';
export const CLIENT = 'CLIENT';
export const GET_CLIENT = 'GET_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_CLIENT_BATCH = 'ADD_CLIENT_BATCH';
export const GET_CLIENTS = 'GET_CLIENTS';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_BATCH = 'DELETE_CLIENT_BATCH';
export const RESTORE_CLIENT = 'RESTORE_CLIENT';
export const ARCHIVE_CLIENT = 'ARCHIVE_CLIENT';
export const SEND_CLIENT_BATCH = 'SEND_CLIENT_BATCH';
export const IMPERSONATE_CLIENT = 'IMPERSONATE_CLIENT';
export const GET_USER_ASSETS = 'get-user-assets';
export const GET_USER_ASSET = 'get-user-asset';
export const GET_CRM_USERS = 'GET_CRM_USERS';
export const GET_TEAM_USERS = 'GET_TEAM_USERS';
export const GET_REVIEW_ENVELOPE_URL = 'GET_REVIEW_ENVELOPE_URL';
export const GET_ONBORD_DOCUMENTS = 'get-onbord-documents';
export const GENERATE_ADVISORY_AGREEMENT = 'generate-advisory-agreement';
export const SEARCH_CRM_CONTACTS = 'SEARCH_CRM_CONTACTS';
export const SEND_CLIENT_TEXT = '/organization/send-client-text';
export const RESEND_CLIENT_TEXT = '/organisations/:organisationsId/clients/:clientsId/resend-text';
export const POST_USER_ASSET = 'POST_USER_ASSET';
export const DOWNLOAD_USER_ASSET = 'DOWNLOAD_USER_ASSET';
export const DELETE_USER_ASSET = 'delete-user-asset';
export const GET_CUSTOM_QUESTIONS = 'GET_CUSTOM_QUESTIONS';
export const ADD_CUSTOM_QUESTION = 'ADD_CUSTOM_QUESTION';
export const DELETE_CUSTOM_QUESTION = 'DELETE_CUSTOM_QUESTION';

// CLIENT
export const INTERVIEW = 'INTERVIEW';
export const AUTHENTICATE_INTERVIEW = 'authenticate-interview';
export const GET_INTERVIEW_PROGRESS = 'GET_INTERVIEW_PROGRESS';
export const SEND_DESKTOP_LINK = '/interview/email-invitation';
export const PURGE_AUTHENTICATED_INTERVIEW = 'purge-interview';
export const SET_WORKING_CLIENT = 'setWorkingClient';
export const UPDATE_INTERVIEW = 'UPDATE_INTERVIEW';
export const UPLOAD_CLIENT_DOCUMENT = 'UPLOAD_CLIENT_DOCUMENT';
export const FINISH_INTERVIEW = 'FINISH_INTERVIEW';

// CLIENT TRANSITIONS
export const TRANSITION_BATCH = 'TRANSITION_BATCH'; // action to upload csv file
export const GET_TRANSITIONING_CLIENTS = 'GET_TRANSITIONING_CLIENTS'; // action to get the parsed data from the uploaded csv
export const GET_TRANSITION = 'GET_TRANSITION';
export const TRANSITION_CLIENTS = 'TRANSITION_CLIENTS'; // action to transition the clients
export const SET_SELECTED_TRANSITION_TYPE = 'SET_SELECTED_TRANSITION_TYPE'; //action to set the transition type
export const STORE_HOUSEHOLD_VALUE = 'STORE_HOUSEHOLD_VALUE';
export const ADD_TRANSITION_NAME = 'ADD_TRANSITION_NAME';
export const ADD_TRANSITION_PHONE = 'ADD_TRANSITION_PHONE';
export const ADD_TRANSITION_EMAIL = 'ADD_TRANSITION_EMAIL';
export const TRANSITION_ADD_SECOND = 'TRANSITION_ADD_SECOND';
export const ADD_TRANSITION_HOUSEHOLDS = 'ADD_TRANSITION_HOUSEHOLDS';
export const ADD_FIRM_ROLES = 'ADD_FIRM_ROLES';
export const EMPTY_TRANSITION_HOUSEHOLDS = 'EMPTY_TRANSITION_HOUSEHOLDS';
export const EMPTY_TRANSITIONING_HOUSEHOLD = 'EMPTY_TRANSITIONING_HOUSEHOLD';
export const GET_TRANSITIONING_HOUSEHOLDS = 'GET_TRANSITIONING_HOUSEHOLDS';
export const DELETE_TRANSITION = 'DELETE_TRANSITION';
export const DELETE_BATCH_TRANSITIONS = 'DELETE_BATCH_TRANSITIONS'
export const EDIT_TRANSITION = 'EDIT_TRANSITION';
export const SET_WORKING_TRANSITION = 'SET_WORKING_TRANSITION';
export const GET_TRANSITION_URL = 'GET_TRANSITION_URL';
export const GET_CLIENT_URL = 'GET_CLIENT_URL';
export const DOWNLOAD_CSV_CLIENTS_TEMPLATE = 'DOWNLOAD_CSV_CLIENTS_TEMPLATE';
export const DOWNLOAD_CSV_TRANSITIONS_TEMPLATE =
  'DOWNLOAD_CSV_TRANSITIONS_TEMPLATE';
export const DOWNLOAD_CSV_PROTOCOL_TRANSITIONS_TEMPLATE =
  'DOWNLOAD_CSV_PROTOCOL_TRANSITIONS_TEMPLATE';
export const DOWNLOAD_CSV_NONPROTOCOL_TRANSITIONS_TEMPLATE =
  'DOWNLOAD_CSV_NONPROTOCOL_TRANSITIONS_TEMPLATE';
export const STATEMENT_UPLOAD = 'STATEMENT_UPLOAD';
export const REGISTER_EMAIL = 'REGISTER_EMAIL';
export const GET_TRANSITION_PUBLIC_INFO = 'GET_TRANSITION_PUBLIC_INFO';
export const UPDATE_TRANSITIONS_SMS_TEMPLATE =
  'UPDATE_TRANSITIONS_SMS_TEMPLATE';
export const SEND_TRANSITION_SMS = 'SEND_TRANSITION_SMS';
export const SEND_BATCH_TRANSITION_SMS = 'SEND_BATCH_TRANSITION_SMS';

// LOGS
export const GET_LOGS = 'GET_LOGS';
export const RETRY_LOG = 'RETRY_LOG';

// Add this new action type
export const GET_CLIENT_STATUSES = 'GET_CLIENT_STATUSES';

export const ActionApiRouteMapper = {
  [PING]: '/health',
  [LOGIN]: '/auth/login',
  [LOGOUT]: '/auth/logout',
  [VERIFY_CODE]: '/auth/login/verify',
  [SEND_PASSWORD_RESET_EMAIL]: '/auth/reset',
  [RESET_PASSWORD]: '/auth/set-password',
  [SET_NEW_PASSWORD]: '/auth/set-new-password',
  [GET_USER]: '/organisations/:organisationsId/advisors/:advisorsId',
  [EDIT_USER]: '/organisations/:organisationsId/advisors/:advisorsId',
  [GET_USERS]: '/organisations/:organisationsId/advisors',
  [GET_TEAM_USERS]: '/organisations/:organisationsId/advisors',
  [GET_CURRENT_USER]: '/organisations/:organisationsId/advisors/:advisorsId',
  [GET_COMPANY]: '/organisations/:organisationsId',
  [GET_CURRENT_COMPANY]: '/organisations/:organisationsId',
  [UPDATE_COMPANY_INFO]: '/organisations/:organisationsId',
  [UPDATE_COMPANY_ADDRESS]: '/organisations/:organisationsId',
  [UPDATE_COMPANY_CRM]: '/organisations/:organisationsId',
  [COMPANY_ASSETS]: '/organisations/:organisationsId',
  [SEARCH_CRM_CONTACTS]: '/organisations/:organisationsId/advisors/crm/clients',
  [ADD_NEW_USER]: '/organisations/:organisationsId/advisors',
  [ADD_USER]: '/organisations/:organisationsId/advisors/with-adv2b',
  [GET_COMPANIES]: '/organisations',
  [ADD_COMPANY]: '/organisations',
  [CLIENT]: '/organisations/:organisationsId/clients',
  [GET_CLIENT]: '/organisations/:organisationsId/clients/:clientsId',
  [GET_CLIENTS]: '/organisations/:organisationsId/clients/contacts',
  [ADD_CLIENT]: '/organisations/:organisationsId/clients',
  [ADD_CLIENT_BATCH]: '/organisations/:organisationsId/clients/batch',
  [SEND_CLIENT_BATCH]: '/organisations/:organisationsId/clients/batch/sms',
  [DELETE_CLIENT_BATCH]:
    '/organisations/:organisationsId/clients/batch/archive',
  [EDIT_CLIENT]: '/organisations/:organisationsId/clients/:clientsId',
  [RESTORE_CLIENT]:
    '/organisations/:organisationsId/clients/:clientsId/unarchive',
  [ARCHIVE_CLIENT]:
    '/organisations/:organisationsId/clients/:clientsId/archive',
  [DELETE_CLIENT]: '/organisations/:organisationsId/clients',
  [IMPERSONATE_CLIENT]: '/interviews/client/:clientsId',
  [GET_CLIENT_URL]: '/organisations/:organisationsId/clients/:clientId/url',
  [UPDATE_MOBILE_PHONE]: '/organisations/:organisationsId/advisors/:advisorsId',
  [MASTER_ACCOUNT_NUMBER]: '/organisations/:organisationsId/master-accounts',
  [ADD_MASTER_ACCOUNT_NUMBER]:
    '/organisations/:organisationsId/master-accounts',
  [DELETE_MASTER_ACCOUNT_NUMBER]:
    '/organisations/:organisationsId/master-accounts',
  [CHANGE_PASSWORD]: '/auth/change-password',
  [GET_SELF]: '/organisation/get-self',
  [EDIT_SELF]: '/organisations/:organisationsId/advisors/:advisorsId',
  [CHANGE_LOGIN_EMAIL]: '/organisations/:organisationsId/advisors/:advisorsId',
  // TODO:  Modify this to use the new API
  [LINK_REDTAIL]:
    '/organisations/:organisationsId/advisors/:advisorsId/crm/link',
  // TODO:  Modify this to use the new API
  [LINK_WEALTHBOX]: '/integrations/wealthbox/link',
  [LINK_SALESFORCE]: '/integrations/salesforce/link',
  [LINK_PRACTIFI]: '/integrations/practifi/link',
  [UNLINK_CRM]:
    '/organisations/:organisationsId/advisors/:advisorsId/crm/unlink',
  [GET_DOCUSIGN_CONSENT_URL]: '/integrations/docusign/authorization-uri',
  [UNLINK_DOCUSIGN]: '/organisations/:organisationsId/docusign/unlink',
  [UNLINK_DOCUSIGN_USER]:
    '/organisations/:organisationsId/advisors/:advisorsId/docusign/unlink',
  [CHOOSE_DOCUSIGN_ACCOUNT_OWNERSHIP]:
    '/organisations/:organisationsId/docusign/ownership',
  [CHOOSE_DOCUSIGN_USER_ACCOUNT_OWNERSHIP]:
    'organisations/:organisationsId/advisors/:advisorsId/docusign/ownership',
  [CHOOSE_DOCUSIGN_USER_ACCOUNT]:
    '/organisations/:organisationsId/advisors/:advisorsId/docusign/account',
  [CHOOSE_DOCUSIGN_ACCOUNT]: '/organisations/:organisationsId/docusign/account',
  [CHOOSE_DOCUSIGN_USER_ACCOUNT_OWNERSHIP]:
    'organisations/:organisationsId/advisors/:advisorsId/docusign/ownership',
  [GET_CRM_USERS]: '/organisations/:organisationsId/advisors/crm/users',
  [INTERVIEW]: '/interviews',
  [GET_INTERVIEW_PROGRESS]: '/interviews/:interviewsId',
  [UPDATE_INTERVIEW]: '/interviews/:interviewsId',
  [FINISH_INTERVIEW]: '/interviews/:interviewsId/finish',
  [UPLOAD_CLIENT_DOCUMENT]: '/interviews/:interviewsId/upload',
  [GET_REVIEW_ENVELOPE_URL]:
    '/organisations/:organisationsId/clients/:clientsId/interview/reviewEnvelope',
  [GET_DOCUSIGN_DOCUMENTS]:
    '/organisations/:organisationsId/docusign/documents',
  [UPSERT_DOCUSIGN_DOCUMENT]:
    '/organisations/:organisationsId/docusign/documents',
  [UPLOAD_COMPANY_ASSET]: '/organisations/:organisationsId/documents/upload',
  [DOWNLOAD_COMPANY_ASSET]:
    '/organisations/:organisationsId/documents/download',
  [UPLOAD_LOGO]: '/organisations/:organisationsId/logo/upload',
  [POST_USER_ASSET]:
    '/organisations/:organisationsId/advisors/:advisorsId/documents/upload',
  [DOWNLOAD_USER_ASSET]:
    '/organisations/:organisationsId/advisors/:advisorsId/documents/download',
  [GET_CUSTOM_QUESTIONS]: '/organisations/:organisationsId/custom-questions',
  [ADD_CUSTOM_QUESTION]: '/organisations/:organisationsId/custom-questions',
  [DELETE_CUSTOM_QUESTION]: '/organisations/:organisationsId/custom-questions',
  [UPDATE_STATUS]: '/organisations/:organisationsId/status',
  [GET_AVAILABLE_CRMS]: '/organisations/:organisationsId/available-crms',
  [GET_CUSTOM_QUESTIONS_TEMPLATES]:
    '/organisations/:organisationsId/interview-templates/custom-questions',
  [ADD_CUSTOM_QUESTION_TEMPLATE]:
    '/organisations/:organisationsId/interview-templates/custom-questions',
  [GET_CUSTOM_QUESTIONS_TEMPLATE]:
    '/organisations/:organisationsId/interview-templates/:templatesId',
  [DELETE_CUSTOM_QUESTION_TEMPLATE]:
    '/organisations/:organisationsId/interview-templates/custom-questions/:templatesId',
  [UPDATE_CUSTOM_QUESTION_TEMPLATE]:
    '/organisations/:organisationsId/interview-templates/custom-questions/:templatesId',
  [TOGGLE_DOCUSIGN]: '/organisations/:organisationsId/toggle-feature',
  // CLIENT TRANSITIONS
  [TOGGLE_TRANSITIONS]: '/organisations/:organisationsId/toggle-feature',
  [TOGGLE_TRANSITION_NOTIFICATIONS]:
    '/organisations/:organisationsId/toggle-feature',
  [GET_TRANSITIONING_CLIENTS]:
    '/organisations/:organisationsId/transition-clients',
  [GET_TRANSITION]:
    '/organisations/:organisationsId/transition-clients/:transitionsId',
  [DELETE_TRANSITION]: '/organisations/:organisationsId/transition-clients',
  [DELETE_BATCH_TRANSITIONS]: '/organisations/:organisationsId/transition-clients/transition-batch-delete',
  [EDIT_TRANSITION]:
    '/organisations/:organisationsId/transition-clients/:transitionsId',
  [TRANSITION_BATCH]:
    '/organisations/:organisationsId/transition-clients/batch/csv',
  [TRANSITION_CLIENTS]:
    '/organisations/:organisationsId/transition-clients/batch',
  [DELETE_TRANSITION]: '/organisations/:organisationsId/transition-clients',
  [GET_TRANSITION_URL]: '/organisations/:organisationsId/transition-clients/:transitionsId/url',
  [DOWNLOAD_CSV_CLIENTS_TEMPLATE]: '/files/csv/clients/template',
  [DOWNLOAD_CSV_TRANSITIONS_TEMPLATE]:
    '/files/csv/transitions/protocol/template',
  [DOWNLOAD_CSV_PROTOCOL_TRANSITIONS_TEMPLATE]:
    '/files/csv/transitions/protocol/template',
  [DOWNLOAD_CSV_NONPROTOCOL_TRANSITIONS_TEMPLATE]:
    '/files/csv/transitions/nonprotocol/template',
  [REGISTER_EMAIL]: '/transitions/:transitionsId/email',
  [GET_TRANSITION_PUBLIC_INFO]: '/transitions/:transitionsId/info',
  [STATEMENT_UPLOAD]: '/transitions/:transitionsId/brokerage-statement',
  [UPDATE_TRANSITIONS_SMS_TEMPLATE]:
    '/organisations/:organisationsId/transition-clients/sms-template',
  [SEND_TRANSITION_SMS]:
    '/organisations/:organisationsId/transition-clients/:transitionsId/send-text',
  [SEND_BATCH_TRANSITION_SMS]: '/organisations/:organisationsId/transition-clients/send-batch-text',
  [GET_LOGS]: '/organisations/:organisationsId/logs',
  [RETRY_LOG]: '/organisations/:organisationsId/logs/:id/retry',
  [GET_CLIENT_STATUSES]: '/organisations/:organisationsId/clients/statuses',
  [TOGGLE_SMS_NOTIFICATIONS]: '/organisations/:organisationsId/toggle-feature',
  [RESEND_CLIENT_TEXT]: '/organisations/:organisationsId/clients/:clientsId/resend-text',
  // Email Templates
  [GET_EMAIL_TEMPLATES]: '/organisations/:organisationsId/emailTemplates',
  [GET_EMAIL_TEMPLATE]: '/organisations/:organisationsId/emailTemplates/:emailTemplateId',
  [ADD_EMAIL_TEMPLATE]: '/organisations/:organisationsId/emailTemplates',
  [UPDATE_EMAIL_TEMPLATE]: '/organisations/:organisationsId/emailTemplates/:emailTemplateId',
  [DELETE_EMAIL_TEMPLATE]: '/organisations/:organisationsId/emailTemplates/:emailTemplateId',
  [GET_EMAIL_TYPES]: '/emailTypes',
};
