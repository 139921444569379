<template>
  <div class="step-container client">
    <v-form name="Finish" class="form">
      <v-container class="steps-step-container">
        <v-row class="mb-8">
          <v-col class="instructions">
            <h1>
              <span class="headerlight" :class="{ 'pulsing': hasSyncingSteps }" v-if="hasSyncingSteps">
                Please <strong>wait</strong> while your answers are being saved.<br> This may take a few moments.
              </span>
              <span class="headerlight" v-else-if="!isAllComplete">
                You're
                <strong>close to finishing!</strong>
                Here's what you've
                <strong>skipped:</strong>
              </span>
              <span v-else class="headerlight">
                Everything is <strong> Complete! </strong> Press
                <strong> Finish </strong> to finalize.
              </span>
            </h1>
          </v-col>
        </v-row>
        <div class="justify-center steps-table" v-if="!finishing">
          <page-status
            v-for="step in mappedIncompleteSteps"
            :key="step.name"
            :name="step.name"
            :status="step.status || 'PENDING'"
            :filled="step.filled"
            @click="next(step.route)"
          />
          <div v-if="areStepsComplete" class="all-complete-message">
            No incomplete steps.
          </div>
        </div>
        <div
          class="doc-container"
          v-if="!finishing && requiredDocuments.length > 0"
        >
          <v-row class="mb-8">
            <v-col class="instructions">
              <h1>Required Documents</h1>
            </v-col>
          </v-row>
          <div class="documents-list">
            <div
              v-for="(doc, index) in requiredDocuments"
              :key="doc.name"
              class="document-item"
            >
              <div class="document-info">
                <div class="document-name">{{ getDocumentLabel(doc) }}</div>
                <div class="document-status">
                  <span v-if="doc.uploaded" class="status-chip success">
                    <v-icon small>fas fa-check</v-icon>
                    <span>Uploaded</span>
                    <span class="upload-date">({{ doc.uploaded }})</span>
                  </span>
                  <span v-else-if="!isOptionalDoc(doc)" class="status-chip error">
                    <v-icon small>fas fa-exclamation-triangle</v-icon>
                    <span>Missing</span>
                  </span>
                  <span v-else class="status-chip optional">
                    <span>Optional</span>
                  </span>
                </div>
              </div>
              <div class="document-action">
                <v-file-input
                  v-model="doc.file"
                  prepend-icon=""
                  class="file-input"
                  :ref="`upload-${index}`"
                  hide-details="auto"
                  :clearable="false"
                  accept="application/pdf, image/png, image/jpeg, image/svg+xml, image/gif"
                  @change="uploadDoc(doc)"
                >
                  <template v-slot:prepend-inner>
                    <v-btn
                      outlined
                      color="primary"
                      @click.stop="$refs[`upload-${index}`][0].$refs.input.click()"
                    >
                      <v-icon left>fas fa-upload</v-icon>
                      Choose File
                    </v-btn>
                  </template>
                </v-file-input>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </v-form>
    <bottom-nav
      :nextText="'Finish'"
      :nextDisabled="hasSyncingSteps || !isAllComplete"
      :class="{ 'pulse-button': hasSyncingSteps }"
      v-on:go-prev="prev()"
      v-on:go-next="saveFinish()"
    ></bottom-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BottomNav from '@/components/common/BottomNav';
import InterviewClientMixin from '@/common/mixins/interview.client.mixin';
import UnathenticatedClientMixin from '@/common/mixins/unauthenticated.client.mixin';
import Storage from '@/services/storage.service';
import {
  MENUS,
  CLIENT_EXIT_KEYS,
  LOCAL_KEYS,
  AUTH_TYPES,
} from '@/common/constants';
import {
  UPLOAD_CLIENT_DOCUMENT,
  GET_INTERVIEW_PROGRESS,
  FINISH_INTERVIEW,
} from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';
import PageStatus from '@/components/client/PageStatus.vue';

export default {
  name: 'Finish',

  components: { BottomNav, PageStatus },
  mixins: [InterviewClientMixin, UnathenticatedClientMixin],

  data: () => ({
    finishing: false,
    pollInterval: null
  }),

  validations() {
    return {};
  },

  computed: {
    ...mapGetters(['clientProgress']),

    mappedIncompleteSteps() {
      let customQuestionCount = 0;

      return this.incompleteSteps.map(step => {
        let name = step.name && step.name.trim() ? step.name : null;

        const isCustomQuestion =
            step.route.name === MENUS.CLIENT.CUSTOM_QUESTIONS.id &&
            step.route.params &&
            step.route.params.questionId


        // Special handling for custom questions
        if (isCustomQuestion) {
          if (!name) {
            customQuestionCount++;
            name = `Custom Question ${customQuestionCount}`;
          }

          return {
            ...step,
            name,
            status: this.getStepStatus(step),
            filled: this.isPageComplete(step.route.name, null, step.route.params.questionId)
          };
        }

        // Standard handling for other page types
        return {
          ...step,
          name,
          status: this.getStepStatus(step),
          filled: this.isPageComplete(step.route.name, step.route.params)
        };
      });
    },

    areStepsComplete() {
      return this.mappedIncompleteSteps.length === 0;
    },

    isAllComplete() {
      return this.areStepsComplete && this.documentsComplete;
    },
    documentsComplete() {
      if (this.requiredDocuments.length === 0) {
        return true;
      }

      return (
        this.requiredDocuments.find(
          (d) => !this.isOptionalDoc(d) && d.uploaded === null,
        ) === undefined
      );
    },

    hasSyncingSteps() {
      return this.mappedIncompleteSteps.some(step => step.status === 'IN_PROGRESS'
      );
    },
  },

  methods: {
    getDocumentLabel(doc) {
      const docLabel = doc.label ? `(${doc.label})` : '';
      const docFeature = doc.feature ? `(${doc.feature})` : '';
      const docType = doc.type ? `for ${doc.type}` : '';
      return `${doc.name} ${docFeature} ${docType} ${docLabel}`;
    },
    isOptionalDoc(doc) {
      return ['CancelledCheck', 'BrokerageStatement'].includes(doc.id);
    },
    /* Upload a document */
    uploadDoc(doc) {
      const payload = {
        documentName: doc.id,
        accountId: doc.accountId,
        file: doc.file,
        feature: doc.feature,
      };
      this.finishing = true;

      this.$store
        .dispatch(UPLOAD_CLIENT_DOCUMENT, payload)
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Document successfully uploaded!',
            okTitle: 'Ok',
          });
        })
        .catch((response) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error uploading document!',
              okTitle: 'Ok',
            },
            response,
          );
        })
        .finally(() => {
          this.$store
            .dispatch(GET_INTERVIEW_PROGRESS)
            .finally(() => (this.finishing = false));
        });
    },

    /* Add this new method to check sync status */
    checkSyncStatus() {
      if (!this.clientProgress || !this.clientProgress.progress || !Array.isArray(this.clientProgress.progress)) {
        console.log('clientProgress is not defined or not an array');
        return false;
      }
      return this.clientProgress.progress.every(item => item.status !== 'IN_PROGRESS' && item.status !== 'FAILED' );
    },

    /* Update the saveFinish method */
    saveFinish() {
      const isClient =
          Storage.getLocal(LOCAL_KEYS.AUTH_TYPE) ===
          AUTH_TYPES.UNAUTHENTICATED_CLIENT;

      let route = { name: MENUS.INTERVIEW.UNAUTHENTICATED.id };
      if (isClient) {
        route.params = {
          flag: this.isAllComplete
              ? CLIENT_EXIT_KEYS.EXIT_COMPLETE
              : CLIENT_EXIT_KEYS.EXIT_INCOMPLETE_SAVE,
        };
      } else {
        route = { name: MENUS.CLIENTS.id };
      }

      // Only call finish when all steps are complete and synced
      if (this.isAllComplete) {
        this.stopPolling();
        this.finishing = true;

        // Make one final progress check
        this.$store.dispatch(GET_INTERVIEW_PROGRESS)
            .then(() => {
              // Wait a moment to ensure UI updates
              setTimeout(() => {
                if (this.hasSyncingSteps) {
                  this.finishing = false;
                  NotificationService.alert({
                    type: NOTIFICATION_TYPES.ERROR,
                    title: 'Sync Error',
                    message: 'Please wait until all questionnaire responses have been synced to the CRM before finishing.',
                    okTitle: 'Ok'
                  });
                  this.startPolling();
                  return;
                }

                // If check passes, proceed with finishing
                this.$store.dispatch(FINISH_INTERVIEW)
                    .then(() => {
                      this.goNext(route);
                    })
                    .catch((response) => {
                      this.finishing = false;
                      this.startPolling();
                      NotificationService.alert(
                          {
                            type: NOTIFICATION_TYPES.ERROR,
                            title: 'Error',
                            message: 'Error Finishing the interview process.',
                            okTitle: 'Ok',
                          },
                          response,
                      );
                    });
              }, 300);
            })
            .catch((error) => {
              this.finishing = false;
              this.startPolling(); // Resume polling
              console.error('Error checking progress:', error);
              NotificationService.alert({
                type: NOTIFICATION_TYPES.ERROR,
                title: 'Sync Status Error',
                message: 'Could not verify if all responses are synced. Please try again.',
                okTitle: 'Ok'
              });
            });
      } else {
        this.finishing = true;
        this.goNext(route);
      }
    },

    /* Nav to route selected */
    next(route) {
      this.goNext(route);
    },

    /* Nav to Previous Page in flow */
    prev() {
      const prev =
        this.getPreviousCustomQuestionRoute() ||
        this.lastBeneficiaryRoute ||
        MENUS.CLIENT.BENEFICIAL_INTEREST.id;
      this.goPrev(prev);
    },

    getStatusColor(step) {
      if (!step.filled) return 'grey';
      if (step.status === 'SYNCED') return 'success';
      if (step.status === 'FAILED') return 'error';
      return 'warning';
    },

    getStatusIcon(step) {
      if (!step.filled) return 'fas fa-minus-circle';
      if (step.status === 'SYNCED') return 'fas fa-check-circle';
      if (step.status === 'FAILED') return 'fas fa-exclamation-circle';
      return 'fas fa-sync fa-spin';
    },

    getStatusText(step) {
      if (!step.filled) return 'Not Completed';
      if (step.status === 'SYNCED') return 'Synced';
      if (step.status === 'FAILED') return 'Sync Failed';
      return 'Syncing...';
    },

    startPolling() {
      this.fetchProgress();
      
      this.pollInterval = setInterval(() => {
        this.fetchProgress();
      }, 10000);
    },

    stopPolling() {
      if (this.pollInterval) {
        clearInterval(this.pollInterval);
        this.pollInterval = null;
      }
    },

    fetchProgress() {
      if (!this.finishing && !this.isAllComplete) {
        this.$store.dispatch(GET_INTERVIEW_PROGRESS)
          .catch((error) => {
            console.error('Error fetching progress:', error);
            NotificationService.alert({
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Sync Error',
              message: 'Failed to check interview progress. Please try again.',
              okTitle: 'Ok'
            });
          });
      } else if (this.isAllComplete) {
        this.stopPolling();
      }
    },

    getStepStatus(step) {
      // Special handling for custom questions which need questionId as a separate parameter
      if (step.route.name === MENUS.CLIENT.CUSTOM_QUESTIONS.id && step.route.params && step.route.params.questionId) {
        const page = this.getPage(step.route.name, null, step.route.params.questionId);
        return page ? page.status : 'UNANSWERED';
      }
      
      // Standard handling for other page types
      const page = this.getPage(step.route.name, step.route.params);
      return page ? page.status : 'UNANSWERED';
    },
  },

  created() {
    this.startPolling();
  },

  beforeDestroy() {
    this.stopPolling();
  },

  watch: {
    finishing(newVal) {
      if (newVal) {
        this.stopPolling();
      } else if (!this.isAllComplete) {
        this.startPolling();
      }
    },
    
    isAllComplete(newVal) {
      if (newVal) {
        this.stopPolling();
      } else if (!this.finishing) {
        this.startPolling();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.step-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .form {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 100px; // Extra padding to account for the bottom nav
  }
}

.steps-step-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.steps-table {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.doc-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.all-complete-message {
  text-align: center;
  padding: 16px;
  font-weight: 500;
  color: var(--color-text-secondary);
}

.status-col {
  display: flex;
  align-items: center;
  border-left: 2px solid var(--secondary-color);
  
  .status-indicator {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    .v-icon {
      margin-right: 4px;
    }
    
    span {
      font-size: 14px;
    }
  }
}

.headerlight {
  strong {
    color: var(--primary-color);
  }

  &.pulsing {
    animation: pulse-header 2s ease-in-out infinite;
  }
}

@keyframes pulse-header {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--v-primary-base), 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(var(--v-primary-base), 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(var(--v-primary-base), 0);
  }
}

.pulse-button {
  :deep(.v-btn) {
    animation: pulse 2s infinite;
    position: relative;
    
    &:disabled {
      animation: none;
    }
  }
}

.documents-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.document-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: var(--color-background, white);
  border: 1px solid var(--color-border, #E0E0E0);
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    background: var(--color-background-hover, #F5F5F5);
  }
}

.document-info {
  flex: 1;
  min-width: 0;
  margin-right: 16px;

  .document-name {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-text-primary, #333333);
    margin-bottom: 2px;
  }
}

.document-status {
  display: flex;
  align-items: center;
  gap: 4px;
}

.status-chip {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 500;

  &.success {
    background-color: var(--color-success, #4CAF50);
    color: white;

    .v-icon {
      color: white !important;
    }
  }

  &.error {
    background-color: var(--color-error-light, #FFEBEE);
    color: var(--color-error, #F44336);
  }

  &.optional {
    background-color: var(--color-grey-light, #F5F5F5);
    color: var(--color-grey, #9E9E9E);
  }

  .upload-date {
    font-size: 10px;
    opacity: 0.8;
    color: white;
  }
}

.document-action {
  .file-input {
    max-width: 150px;
  }

  .v-btn {
    height: 32px;
    padding: 0 12px;
    font-size: 13px;
    
    .v-icon {
      font-size: 14px;
      margin-right: 6px;
    }
  }
}

// Update bottom nav positioning
:deep(.bottom-nav) {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 10;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}
</style>
