<template>
  <v-dialog
    v-model="dialogVisible"
    max-width="900px"
    @input="updateVisibility"
  >
    <v-card>
      <v-card-title class="py-2">
        <span class="headline">Preview: {{ templateName }}</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="close">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="dialog-content py-2">
        <v-container class="pa-0">
          <v-row>
            <v-col cols="12">
              <div class="template-info caption text--secondary mb-2">
                <span><strong>Template Name:</strong> {{ templateName }}</span>
                <span class="mx-2">|</span>
                <span><strong>Type:</strong> {{ templateType }}</span>
              </div>
              
              <v-card outlined class="preview-container">
                <v-overlay :value="previewLoading" absolute>
                  <v-progress-circular indeterminate size="50" color="primary"></v-progress-circular>
                </v-overlay>
                <v-card-text>
                  <div class="preview-content" v-html="previewContent || processedContent"></div>
                </v-card-text>
              </v-card>
              
              <div class="caption text--secondary mt-2 d-flex align-center">
                <v-icon x-small color="amber darken-3" class="mr-1">fas fa-info-circle</v-icon>
                <span>Preview shows how your email template will appear with placeholder values.</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-2">
        <v-spacer></v-spacer>
        <v-btn text small @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import PublicAssets from '@/common/mixins/publicasset.mixin';
import { ASSET_KEYS } from '@/common/constants';

export default {
  name: 'TemplatePreviewDialog',
  mixins: [PublicAssets],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    templateName: {
      type: String,
      default: ''
    },
    templateType: {
      type: String,
      default: ''
    },
    placeholders: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible: this.visible,
      previewContent: '',
      mockValues: {},
      previewLoading: false
    };
  },
  computed: {
    ...mapGetters(['currentCompany']),
    organizationLogo() {
      if (!this.currentCompany || !this.currentCompany.assets) return '';
      const logo = this.currentCompany.assets.find(
        (asset) => asset.assetType === ASSET_KEYS.LOGO
      );
      return logo ? logo.assetLocation : '';
    },
    processedContent() {
      return this.processHtmlImages(this.content || '');
    }
  },
  watch: {
    visible(newVal) {
      this.dialogVisible = newVal;
      if (newVal) {
        this.generatePreview();
      }
    },
    content() {
      if (this.dialogVisible) {
        this.generatePreview();
      }
    }
  },
  methods: {
    updateVisibility(value) {
      this.$emit('update:visible', value);
    },
    close() {
      this.dialogVisible = false;
      this.$emit('update:visible', false);
    },
    generatePreview() {
      // Show loading state
      this.previewLoading = true;
      
      // Define standard dimensions for organization logo
      const logoWidth = 200;
      const logoHeight = 70;
      
      // Generate mock values for placeholders
      this.generateMockPlaceholders();
      
      // Get the content to preview
      let previewHtml = this.processedContent;
      
      // Replace all placeholders with mock values
      for (const placeholder in this.mockValues) {
        const regex = new RegExp(`{{${placeholder}}}`, 'g');
        previewHtml = previewHtml.replace(regex, this.mockValues[placeholder]);
      }
      
      // Special handling for organization logo - use actual logo from store
      if (this.organizationLogo) {
        // Flag to track if we've already replaced the logo
        let logoReplaced = false;
        
        // First, handle img tags with the data-placeholder attribute
        const imgRegex = /<img([^>]*)data-placeholder="{{organizationLogo}}"([^>]*)>/gi;
        previewHtml = previewHtml.replace(imgRegex, (match, before, after) => {
          // Set flag that we've replaced the logo
          logoReplaced = true;
          
          // Make sure width and height attributes are included
          if (!match.includes('width=')) {
            after += ` width="${logoWidth}"`;
          }
          if (!match.includes('height=')) {
            after += ` height="${logoHeight}"`;
          }
          // Make sure style attributes include explicit width and height
          if (!match.includes('width:') || !match.includes('height:')) {
            if (match.includes('style=')) {
              // Add width and height to existing style
              after = after.replace(/style="([^"]*)"/, (styleMatch, styleContent) => {
                return `style="${styleContent}; width: ${logoWidth}px; height: ${logoHeight}px;"`;
              });
            } else {
              // Add a new style attribute with width and height
              after += ` style="width: ${logoWidth}px; height: ${logoHeight}px;"`;
            }
          }
          return `<img${before}data-placeholder="{{organizationLogo}}" src="${this.organizationLogo}"${after}>`;
        });
        
        // Only handle direct placeholders if we haven't already replaced an img tag
        if (!logoReplaced) {
          const logoRegex = /{{organizationLogo}}/g;
          previewHtml = previewHtml.replace(logoRegex, 
            `<img src="${this.organizationLogo}" 
                  alt="Organization Logo" 
                  style="max-width: ${logoWidth}px; max-height: ${logoHeight}px; width: ${logoWidth}px; height: ${logoHeight}px;" 
                  width="${logoWidth}" 
                  height="${logoHeight}">`
          );
        }
      }
      
      // Set the preview content with replaced placeholders
      this.previewContent = previewHtml;
      
      // Hide loading after a short delay (for UI feedback)
      setTimeout(() => {
        this.previewLoading = false;
      }, 300);
    },
    generateMockPlaceholders() {
      this.mockValues = {};
      
      // Generate mock values for all placeholders
      this.placeholders.forEach(placeholder => {
        // Extract placeholder name without braces if they exist
        const placeholderName = placeholder.replace(/[{}]/g, '');
        
        // Skip organization logo as we handle it specially
        if (placeholderName.toLowerCase() === 'organizationLogo') {
          return;
        }
        
        // Generate mock values based on placeholder name
        if (placeholderName.toLowerCase().includes('name')) {
          this.mockValues[placeholderName] = 'Sample Name';
        } else if (placeholderName.toLowerCase().includes('email')) {
          this.mockValues[placeholderName] = 'sample@example.com';
        } else if (placeholderName.toLowerCase().includes('date')) {
          this.mockValues[placeholderName] = new Date().toLocaleDateString();
        } else if (placeholderName.toLowerCase().includes('time')) {
          this.mockValues[placeholderName] = new Date().toLocaleTimeString();
        } else if (placeholderName.toLowerCase().includes('phone')) {
          this.mockValues[placeholderName] = '+1 (555) 123-4567';
        } else if (placeholderName.toLowerCase().includes('address')) {
          this.mockValues[placeholderName] = '123 Sample St, City, Country';
        } else if (placeholderName.toLowerCase().includes('link')) {
          this.mockValues[placeholderName] = 'https://example.com';
        } else if (placeholderName.toLowerCase().includes('logo') || placeholderName.toLowerCase().includes('image')) {
          // Use a placeholder image for other logos/images
          this.mockValues[placeholderName] = 'https://via.placeholder.com/200x70?text=Sample+Logo';
        } else {
          // Generic placeholder text
          this.mockValues[placeholderName] = `[${placeholderName} Value]`;
        }
      });
    },
    // Process HTML content to ensure all images have size constraints
    processHtmlImages(htmlContent) {
      if (!htmlContent) return htmlContent;
      
      try {
        // Create a temporary DOM element to parse the HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;
        
        // Find all images in the content
        const images = tempDiv.querySelectorAll('img');
        
        // Set max dimensions for each image
        images.forEach(img => {
          // Add max-width and max-height styles directly to the image tag
          img.style.maxWidth = '250px';
          img.style.maxHeight = '200px';
          img.style.height = 'auto';
          img.style.objectFit = 'contain';
          
          // Also add attributes for email clients that might strip style attributes
          img.setAttribute('width', img.width > 250 ? '250' : img.width.toString());
          
          // Add a class that can be targeted by inline CSS in the email
          img.classList.add('email-template-img');
        });
        
        // Add a style tag with CSS for all images (for email clients that support it)
        const styleTag = document.createElement('style');
        styleTag.textContent = `
          .email-template-img, img {
            max-width: 250px !important;
            max-height: 200px !important;
            height: auto !important;
            object-fit: contain !important;
          }
        `;
        
        // Insert the style tag at the beginning of the content
        if (tempDiv.firstChild) {
          tempDiv.insertBefore(styleTag, tempDiv.firstChild);
        } else {
          tempDiv.appendChild(styleTag);
        }
        
        return tempDiv.innerHTML;
      } catch (error) {
        console.error('Error processing HTML images:', error);
        return htmlContent; // Return original content if there's an error
      }
    }
  }
};
</script>

<style scoped>
.dialog-content {
  max-height: 70vh;
  overflow-y: auto;
}

.headline {
  font-size: 1.25rem !important;
  font-weight: 600;
  color: #1e3b70;
}

.preview-container {
  position: relative;
  min-height: 300px;
  max-height: 60vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.preview-content {
  padding: 16px;
  font-family: 'Arial', sans-serif;
}

.preview-content :deep(img) {
  max-width: 100%;
  height: auto;
}

.preview-content :deep(table) {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
}

.preview-content :deep(th), 
.preview-content :deep(td) {
  border: 1px solid #ddd;
  padding: 8px;
}

.preview-content :deep(th) {
  background-color: #f2f2f2;
  text-align: left;
}

.template-info {
  margin-bottom: 12px;
  padding: 8px;
  background-color: #f5f7f9;
  border-radius: 4px;
}
</style> 